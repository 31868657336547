import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { Skeleton, Form, Select, DatePicker } from 'antd';
import { TFuncKey, useTranslation } from 'react-i18next';

import { TaskFromData } from './types';

import { Button as NewButton } from 'components/Button';

import modalStyles from '../TaskModal/TaskModal.module.scss';
import styles from './TaskForm.module.scss';
import classNames from 'classnames';
import * as Yup from 'yup';
import { apiClient, TaskCommentModel, TaskModel } from 'service/api';
import moment from 'moment-timezone';
import { useFetchTaskData } from './hooks/useFetchTaskData';
import { useGetTaskOptions } from './hooks/useGetTaskOptions';
import { debounce } from 'lodash';
import TextArea from 'components/GeneralFields/TextArea';
import { CalendarDay } from 'pages/Appointment/components/AppointmentForm/components/CalendarDay';
import useApp from 'store/AppContext';
import useQuery from 'hooks/useQuery';
import { AppointmentChangeLogs } from 'pages/Appointment/components/AppointmentChangeLogs/AppointmentChangeLogs';
import { AppointmentComments } from 'pages/Appointment/components/AppointmentView/components/AppointmentComments';
import { TaskStatusTransition } from './components/TaskStatusTransition';

type TaskFormProps = {
  taskId?: string;
  initialValues?: Partial<TaskFromData>;
  onDirtyChange: (isDirty: boolean) => void;
  onSave: (updatedTask: TaskModel) => void;
  onCancel: (shouldRefreshData?: boolean) => void;
};

export const TaskForm = ({
  taskId,
  initialValues: _initialValues,
  onDirtyChange,
  onSave,
  onCancel: _onCancel,
}: TaskFormProps) => {
  const query = useQuery();
  const {
    data: { clinic: selectedClinicId },
  } = useApp();
  const { t } = useTranslation();
  const [taskComments, setTaskComments] = useState<TaskModel['comments']>([]);

  const onCancel = () => {
    _onCancel();
  };

  const [initialValues, setInitialValues] =
    useState<Partial<TaskFromData> | null>(
      _initialValues || taskId ? null : {},
    );

  const { users, customers, pets, task, fetchPets, fetchCustomers } =
    useFetchTaskData({
      taskId,
    });

  useEffect(() => {
    setTaskComments(task?.comments || []);
  }, [task]);

  const { customerOptions, petOptions, userOptions, timeOptions } =
    useGetTaskOptions({
      task,
      customers,
      pets,
      users,
    });

  useEffect(() => {
    if (taskId && task) {
      setInitialValues({
        title: task.title,
        description: task.description,
        date: task.date,
        customerId: task.customerId || null,
        assigneeId: task.assigneeId || null,
        petId: task.petId || null,
        time: task.date
          ? moment(task.date).get('hours') === 4 &&
            moment(task.date).get('minutes') === 0
            ? null
            : moment(task.date).get('hours') +
              moment(task.date).get('minutes') / 60
          : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  //   useEffectOnce(() => {
  //     (async () => {
  //       if (!initialValues && taskId) {
  //         const task = await apiClient.taskAdmin.taskAdminControllerGetOne({
  //           id: taskId,
  //         });
  //         if (task) {
  //           setInitialValues({
  // title: task.id,
  // description: task.description,
  // date: task.date,
  // customerId: task.customerId || undefined,
  // assigneeId: task.assigneeId || undefined,
  // petId: task.petId || undefined,
  //           });
  //         }
  //       }
  //     })();
  //   });

  const taskFormSchemaObject = {
    title: Yup.string().required('validation_required'),
    date: Yup.string().required('validation_required'),
  };

  const taskFormSchema = Yup.object().shape(taskFormSchemaObject);
  const handleUpdate = async (data: TaskFromData) => {
    let date = moment(data.date).set('hours', 4).set('minutes', 0);
    if (data.time !== null && !isNaN(data.time as number)) {
      date = moment(data.date)
        .set('hours', Math.floor(data.time as number))
        .set('minutes', ((data.time as number) % 1) * 60);
    }
    const updatedTask = await apiClient.taskAdmin.taskAdminControllerUpdate({
      requestBody: {
        id: taskId!,
        title: data.title,
        description: data.description,
        date: date.toString(),
        clinicId: selectedClinicId,
        customerId: data.customerId as string | undefined,
        assigneeId: data.assigneeId as string | undefined,
        petId: data.petId as string | undefined,
      },
    });

    if (updatedTask) {
      onSave(updatedTask);
    }
  };

  const handleSave = async (data: TaskFromData) => {
    let date = moment(data.date).set('hours', 4).set('minutes', 0);
    if (data.time !== null && !isNaN(data.time as number)) {
      date = moment(data.date)
        .set('hours', Math.floor(data.time as number))
        .set('minutes', ((data.time as number) % 1) * 60);
    }

    const createdTask = await apiClient.taskAdmin.taskAdminControllerCreate({
      requestBody: {
        title: data.title,
        date: date.toString(),
        description: data.description,
        customerId: data.customerId as string | undefined,
        petId: data.petId as string | undefined,
        assigneeId: data.assigneeId as string | undefined,
        clinicId: selectedClinicId!,
      },
    });

    if (createdTask) {
      onSave(createdTask);
    }
  };

  const handleTrySave = (values: TaskFromData) => {
    if (!isDirty) {
      return;
    }
    if (taskId) {
      handleUpdate(values);
    } else {
      handleSave(values);
    }
  };

  const getValidationProps = (
    errorMessage?: string,
  ): { help?: string; validateStatus: 'error' | 'success' } => {
    return {
      help: errorMessage && t(errorMessage as TFuncKey),
      validateStatus: typeof errorMessage === 'string' ? 'error' : 'success',
    };
  };

  const defaultValues = useMemo(() => {
    return {
      title: '',
      description: undefined,
      customerId: null,
      assigneeId: null,
      petId: null,
      date: query.get('date') || moment().toString(),
      time: null,
      comment: '',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    handleSubmit,
    setFieldValue,
    values: formValues,
    errors: formErrors,
    submitCount,
    dirty: isDirty,
    isSubmitting,
    resetForm,
  } = useFormik<TaskFromData>({
    enableReinitialize: true,
    initialValues: {
      ...defaultValues,
      ...initialValues,
    },
    validationSchema: taskFormSchema,
    onSubmit: handleTrySave,
  });

  useEffect(() => {
    onDirtyChange(isDirty);
  }, [isDirty, onDirtyChange]);

  const errors = submitCount ? formErrors : {};

  // async function onAction(key: string) {
  //   switch (key) {
  //     case 'active':
  //       await apiClient.userAdmin.userAdminControllerUpdate({
  //         requestBody: {
  //           id: taskId as string,
  //           // isActive: !formValues['isActive'],
  //         },
  //       });
  //       // setFieldValue('isActive', !formValues['isActive']);
  //       setShouldRefreshDataOnCancel(true);
  //       break;
  //     default:
  //       return;
  //   }
  // }

  const debouncedCustomerSearch = debounce(fetchCustomers, 500);

  // const actionsMenu = (
  //   <Menu onClick={(event) => onAction(event.key)}>
  //     <Menu.Item key={'active'}>
  //       <CloseSquareOutlined className="icon" />
  //       {t('mark_as')}
  //       {/* {formValues['isActive'] && t('not')}  */}
  //       {t('active')}
  //     </Menu.Item>
  //   </Menu>
  // );

  if (!initialValues) {
    return (
      <div className="p-4">
        <Skeleton active />
      </div>
    );
  }

  return (
    <div className={classNames(modalStyles.wrapper, styles.container)}>
      <Form size="large" layout="vertical" onSubmitCapture={handleSubmit}>
        <div className="flex mb-4 -mx-3">
          <div className={classNames('px-3', styles.formFields)}>
            {false ? (
              <>
                <div></div>
              </>
            ) : (
              <>
                <div className={styles.sideFieldsContainer}>
                  <div className={styles.sideFields}>
                    <Form.Item
                      className={classNames(styles.sideField)}
                      label={t('assignee')}
                      {...getValidationProps(errors.petId)}>
                      <Select
                        options={userOptions}
                        showSearch
                        value={formValues.assigneeId}
                        optionFilterProp="label"
                        placeholder={t('assignee')}
                        onChange={(assigneeId: string) => {
                          setFieldValue('assigneeId', assigneeId);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className={styles.sideField}
                      label={t('date')}
                      {...getValidationProps(errors.date)}>
                      <DatePicker
                        disabledDate={(date: moment.Moment) => {
                          return moment().subtract(1, 'days').isAfter(date);
                        }}
                        // disabled={!allowEditOrCreate}
                        format={'DD.MM.YYYY'}
                        allowClear={false}
                        placeholder={t('date_placeholder')}
                        value={moment(formValues.date)}
                        dateRender={(date) => <CalendarDay date={date} />}
                        onChange={(date: moment.Moment | null) => {
                          setFieldValue('date', date);
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label={t('time')}
                      {...getValidationProps(errors.time)}>
                      <Select
                        options={timeOptions}
                        showSearch
                        optionFilterProp="label"
                        value={formValues.time}
                        onChange={async (time: number | null) => {
                          setFieldValue('time', time);

                          //   fetchDurations({
                          //     requestBody: {
                          //       time,
                          //       date: moment(formValues.date).format('YYYY-MM-DD'),
                          //       doctorId: formValues.doctor!,
                          //       appointmentId,
                          //     },
                          //   });
                        }}
                        // disabled={!allowEditOrCreate}
                      />
                    </Form.Item>

                    <Form.Item
                      className={classNames(
                        styles.sideField,
                        formValues.customerId === null ? styles.isNone : null,
                      )}
                      label={t('customer')}
                      {...getValidationProps(errors.customerId)}>
                      <Select
                        options={customerOptions}
                        showSearch
                        filterOption={false}
                        value={formValues.customerId}
                        placeholder={t('select_customer')}
                        onSearch={debouncedCustomerSearch}
                        onChange={async (customerId: string) => {
                          setFieldValue('customerId', customerId);

                          if (!customerId) {
                            setFieldValue('petId', undefined);
                          } else {
                            fetchPets({
                              customerId,
                            });
                            if (formValues.customerId !== customerId) {
                              setFieldValue('petId', undefined);
                            }

                            // const pet = pets?.find((p) => !p.deceasedAt);
                            // if (pet) {
                            //   setFieldValue('petId', pet?.id);
                            // }
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className={classNames(
                        styles.sideField,
                        formValues.petId === null ? styles.isNone : null,
                      )}
                      label={t('pet')}
                      {...getValidationProps(errors.petId)}>
                      <Select
                        options={petOptions}
                        showSearch
                        value={formValues.petId}
                        optionFilterProp="label"
                        placeholder={t('select_pet')}
                        onChange={(petId: string) => {
                          setFieldValue('petId', petId);
                        }}
                      />
                    </Form.Item>
                  </div>
                  {task && (
                    <div className={styles.taskStatusTransition}>
                      <TaskStatusTransition task={task} onSave={onSave} />
                    </div>
                  )}
                  {task ? (
                    <AppointmentChangeLogs
                      changeLogs={task?.changeLogs.map((e) => [e]) ?? []}
                    />
                  ) : null}
                </div>
              </>
            )}
            <div className={styles.coreFields}>
              <Form.Item
                className={classNames(
                  styles.commentInput,
                  styles.titleField,
                  styles.withMaxLength,
                )}
                {...getValidationProps(errors.title)}>
                <span className={styles.maxLength}>
                  {(formValues?.title as string)?.length || 0} / 160
                </span>
                <TextArea
                  autoSize
                  size="large"
                  allowClear={false}
                  value={formValues.title}
                  placeholder={t('title')}
                  onChange={(title: string) => {
                    setFieldValue('title', title);
                  }}
                  maxLength={160}
                />
              </Form.Item>
              <Form.Item
                className={classNames(
                  styles.commentInput,
                  styles.descriptionField,
                  styles.withMaxLength,
                )}
                {...getValidationProps(errors.description)}>
                <span className={styles.maxLength}>
                  {(formValues?.description as string)?.length || 0} / 800
                </span>
                <TextArea
                  autoSize
                  allowClear={false}
                  size="large"
                  value={formValues.description}
                  placeholder={t('description')}
                  onChange={(description: string) => {
                    setFieldValue('description', description);
                  }}
                  maxLength={800}
                />
              </Form.Item>
              {task && (
                <AppointmentComments
                  forTask
                  modelId={task.id}
                  value={formValues.comment}
                  setValue={(val) => setFieldValue('comment', val)}
                  comments={taskComments}
                  setComments={(comments) => {
                    setTaskComments(comments as TaskCommentModel[]);
                    if (
                      comments &&
                      taskComments &&
                      comments?.length > taskComments?.length
                    ) {
                      resetForm({ values: { ...formValues, comment: '' } });
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-2">
          {/* {taskId && (
            <Dropdown overlay={actionsMenu} trigger={['click']}>
              <Button icon={<DownOutlined />}> {t('other_actions')} </Button>
            </Dropdown>
          )} */}
          <div className="flex-1" />
          <NewButton type="secondary" onClick={onCancel}>
            {t('cancel')}
          </NewButton>
          <NewButton
            type="primary"
            htmlType="submit"
            disabled={isSubmitting || !isDirty}>
            {t('save')}
          </NewButton>
        </div>
      </Form>
    </div>
  );
};
