import { MenuItem } from '../MenuData';

export const getKeysOfParentNodes = (
  tree: MenuItem[],
  childNodeKey: string,
  currentPath: string[] = [],
): string[] | undefined => {
  for (let j = 0; j < tree.length; j++) {
    let x = tree[j];

    if (x.key === childNodeKey) {
      return currentPath;
    }

    if (x.children) {
      for (let i = 0; i < x.children.length; i++) {
        const result = getKeysOfParentNodes(x.children, childNodeKey, [
          ...currentPath,
          x.key,
        ]);

        if (result) return result;
      }
    }
  }
};
