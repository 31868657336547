/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppointmentModel } from './AppointmentModel';
import type { ClinicModel } from './ClinicModel';
import type { FileModel } from './FileModel';
import type { LanguageModel } from './LanguageModel';
import type { PetCategoryModel } from './PetCategoryModel';
import type { SpecialWorkingHoursModel } from './SpecialWorkingHoursModel';
import type { TreatmentModel } from './TreatmentModel';
import type { UserModel } from './UserModel';

export type DoctorWithTreatmentStatus = {
    name: string;
    email: string;
    role: DoctorWithTreatmentStatus.role;
    createdAt: string;
    picture: FileModel;
    pictureId: string;
    userId: string | null;
    extra: any;
    clinics: Array<ClinicModel>;
    appointments: Array<AppointmentModel>;
    treatments: Array<TreatmentModel>;
    languages: Array<LanguageModel>;
    specialWorkingHours: Array<SpecialWorkingHoursModel>;
    user: UserModel;
    petCategories: Array<PetCategoryModel>;
    isReal: boolean;
    id: string;
};

export namespace DoctorWithTreatmentStatus {

    export enum role {
        GOD = 'god',
        MANAGER = 'manager',
        CUSTOMER = 'customer',
        ANONYMOUS = 'anonymous',
        DOC = 'doc',
        ASSISTANT = 'assistant',
    }


}

