/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetUsersDto = {
    fullName?: string;
    email?: string;
    phoneNumber?: string;
    role?: GetUsersDto.role;
    clinicId?: string;
    isActive?: boolean;
    limit?: number;
};

export namespace GetUsersDto {

    export enum role {
        GOD = 'god',
        MANAGER = 'manager',
        CUSTOMER = 'customer',
        ANONYMOUS = 'anonymous',
        DOC = 'doc',
        ASSISTANT = 'assistant',
    }


}

