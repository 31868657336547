import React, { useState } from 'react';
import { GenericModal, GenericModalProps } from './components/GenericModal';
import { useTranslation } from 'react-i18next';

export type ModalProps = {
  onConfirm?: GenericModalProps['onConfirm'];
  onDecline?: GenericModalProps['onDecline'];
  loading?: GenericModalProps['loading'];
  title: GenericModalProps['title'];
  description?: GenericModalProps['description'];
  declineButtonText?: GenericModalProps['declineButtonText'];
  confirmButtonText?: GenericModalProps['confirmButtonText'];
  declineOnCancel?: GenericModalProps['declineOnCancel'];
};

export const useModal = () => {
  const [modalProps, setModalProps] = useState<ModalProps | null>();
  const { t } = useTranslation();

  return {
    modal: modalProps ? (
      <GenericModal
        isOpen={true}
        setIsOpen={(val) => {
          if (val === false) {
            setModalProps(null);
          }
        }}
        title={modalProps.title}
        description={modalProps.description}
        declineButtonText={modalProps.declineButtonText || t('cancel')}
        confirmButtonText={modalProps.confirmButtonText || t('yes')}
        loading={modalProps.loading}
        onConfirm={modalProps.onConfirm}
        onDecline={modalProps.onDecline}
        declineOnCancel={modalProps.declineOnCancel}
      />
    ) : null,
    openModal: (props: ModalProps) => setModalProps(props),
  };
};
