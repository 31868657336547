import React from 'react';
import { Result } from 'antd';

interface ErrorScreenProps {
  error: Error;
}

const isDev = process.env.NODE_ENV === 'development';

export const ErrorScreen = ({ error }: ErrorScreenProps) => {
  return (
    <Result status="error" title="Something went wrong">
      {isDev && <pre>{error.message}</pre>}
    </Result>
  );
};
