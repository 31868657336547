/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LegalNoticeModel = {
    createdAt: string;
    updatedAt: string;
    type: LegalNoticeModel.type;
    version: number;
    content: any;
    name: any;
    id: string;
};

export namespace LegalNoticeModel {

    export enum type {
        PRIVACY_POLICY = 'privacy_policy',
        TREATMENT_POLICY = 'treatment_policy',
        BOOKING_POLICY = 'booking_policy',
        CARD_ON_FILE_POLICY = 'card_on_file_policy',
    }


}

