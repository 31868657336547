import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerModel, PetModel, TaskModel, UserModel } from 'service/api';

type GetOptionsParams = {
  task?: TaskModel;
  pets?: PetModel[];
  customers?: CustomerModel[];
  users?: UserModel[];
};

export const useGetTaskOptions = ({
  pets,
  customers,
  users,
  task,
}: GetOptionsParams) => {
  const { t } = useTranslation();
  const petOptions = useMemo(() => {
    const petsList = pets || [];
    const _petOptions =
      petsList
        ?.filter((pet) => !pet.hasDeceased)
        .map((d) => ({ value: d.id, label: d.name })) || [];
    return [{ value: null, label: t('none') }, ..._petOptions];
  }, [pets, t]);

  const customerOptions = useMemo(() => {
    const customersList = customers || [];
    if (
      task?.customer &&
      !customers?.find((e) => e.id === task?.customer?.id)
    ) {
      customersList.push(task.customer);
    }
    const _customerOptions = customersList?.map((d) => ({
      value: d.id,
      label: `${d.firstName} ${d.lastName}${d.email ? ` (${d.email})` : ''}`,
    }));

    return [{ value: null, label: t('none') }, ..._customerOptions];
  }, [customers, t, task]);

  const userOptions = useMemo(() => {
    const _userOptions =
      users?.map((user) => {
        return {
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        };
      }) || [];
    return [{ value: null, label: t('all') }, ..._userOptions];
  }, [t, users]);

  const timeOptions = useMemo(() => {
    const times: { value: null | number; label: string }[] = [
      { value: null, label: t('all_day') },
    ];
    for (let i = 7; i < 21; i += 0.25) {
      const hours = Math.floor(i);
      const minutes = (i % 1) * 60;
      const label = `${hours < 10 ? `0${hours}` : `${hours}`}:${
        minutes < 10 ? `0${minutes}` : `${minutes}`
      }`;
      times.push({ value: i, label: label });
    }
    return times;
  }, [t]);

  return {
    petOptions,
    customerOptions,
    userOptions,
    timeOptions,
  };
};
