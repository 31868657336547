import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import {
  AppointmentCommentModel,
  AppointmentModel,
  apiClient,
  TaskModel,
  TaskCommentModel,
} from 'service/api';
import { Comment } from './components';
import styles from './AppointmentComments.module.scss';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { Button as NewButton } from 'components/Button';
import { TextAreaRef } from 'antd/lib/input/TextArea';

export type AppointmentCommentsProps = {
  forTask?: boolean;
  modelId: string;
  comments: AppointmentModel['comments'] | TaskModel['comments'];
  setComments: (
    comments: AppointmentModel['comments'] | TaskModel['comments'],
  ) => void;
  value: string;
  setValue: (val: string) => void;
};

export const AppointmentComments: React.FC<AppointmentCommentsProps> = ({
  modelId,
  comments,
  setComments,
  value,
  setValue,
  forTask,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<TextAreaRef>(null);
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addAppointmentComment = useCallback(() => {
    return apiClient.appointmentCommentAdmin.appointmentResourcesControllerAddComment(
      {
        requestBody: {
          appointmentId: modelId,
          text: value,
        },
      },
    );
  }, [modelId, value]);

  const addTaskComment = useCallback(() => {
    return apiClient.taskAdmin.taskAdminControllerAddComment({
      requestBody: {
        taskId: modelId,
        text: value,
      },
    });
  }, [modelId, value]);

  const updateAppointmentComment = useCallback(
    (commentId: string) => {
      return apiClient.appointmentCommentAdmin.appointmentResourcesControllerUpdateComment(
        {
          requestBody: {
            id: commentId,
            text: value,
          },
        },
      );
    },
    [value],
  );

  const updateTaskComment = useCallback(
    (commentId: string) => {
      return apiClient.taskAdmin.taskAdminControllerUpdateComment({
        requestBody: {
          id: commentId,
          text: value,
        },
      });
    },
    [value],
  );

  const deleteAppointmentComment = useCallback((commentId: string) => {
    return apiClient.appointmentCommentAdmin.appointmentResourcesControllerDeleteComment(
      {
        requestBody: {
          id: commentId,
        },
      },
    );
  }, []);

  const deleteTaskComment = useCallback((commentId: string) => {
    return apiClient.taskAdmin.taskAdminControllerDeleteComment({
      requestBody: {
        id: commentId,
      },
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      let updatedModel: AppointmentModel | TaskModel;
      if (editingCommentId) {
        updatedModel = forTask
          ? await updateTaskComment(editingCommentId)
          : await updateAppointmentComment(editingCommentId);
      } else {
        updatedModel = forTask
          ? await addTaskComment()
          : await addAppointmentComment();
      }
      setComments(updatedModel.comments);
      setValue('');
      setEditingCommentId(null);
    } catch (error) {}

    setIsLoading(false);
  }, [
    editingCommentId,
    setComments,
    setValue,
    forTask,
    updateTaskComment,
    updateAppointmentComment,
    addTaskComment,
    addAppointmentComment,
  ]);

  const handleDelete = useCallback(
    async (id: string) => {
      setIsLoading(true);
      try {
        const updatedModel = forTask
          ? await deleteTaskComment(id)
          : await deleteAppointmentComment(id);
        setComments(updatedModel.comments);
        if (editingCommentId === id) {
          setEditingCommentId(null);
          setValue('');
        }
      } catch (error) {}
      setIsLoading(false);
    },
    [
      deleteAppointmentComment,
      deleteTaskComment,
      editingCommentId,
      forTask,
      setComments,
      setValue,
    ],
  );

  const handleEditStart = useCallback(
    (comment: AppointmentCommentModel | TaskCommentModel) => {
      setValue(comment.text);
      setEditingCommentId(comment.id);
      inputRef?.current?.focus();
    },
    [setValue],
  );

  const handleCancel = useCallback(() => {
    setEditingCommentId(null);
    setValue('');
  }, [setValue]);

  return (
    <div className={styles.container}>
      <div className={styles.commentInputContainer}>
        <Form.Item label={t('internal_comment')}>
          <Input.TextArea
            ref={inputRef}
            autoSize
            value={value}
            rows={1}
            disabled={isLoading}
            placeholder={t('add_new_comment')}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              setValue(event.target.value);
            }}
          />
        </Form.Item>
        <div className={styles.buttons}>
          {editingCommentId && (
            <div className={styles.cancelButtonContainer}>
              <NewButton
                type="tertiary"
                htmlType="button"
                onClick={handleCancel}
                disabled={isLoading}>
                {t('cancel')}
              </NewButton>
            </div>
          )}
          {value && (
            <div className={styles.submitButtonContainer}>
              <NewButton
                type="primary"
                htmlType="button"
                onClick={handleSubmit}
                disabled={isLoading}>
                {editingCommentId ? t('save') : t('add')}
              </NewButton>
            </div>
          )}
        </div>
      </div>

      <div className={styles.commentsContainer}>
        {comments &&
          comments.map((comment) => {
            return (
              <Comment
                key={comment.id}
                comment={comment}
                onDelete={handleDelete}
                onEditStart={handleEditStart}
                isCurrentlyEditing={comment.id === editingCommentId}
              />
            );
          })}
      </div>
    </div>
  );
};
