import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';

import { UserModel, apiClient } from 'service/api';

type AuthContextValue = {
  authData?: UserModel;
};

const AuthContext = createContext<AuthContextValue | null>(null);

export const AuthContextProvider = ({
  children,
  afterAuth,
}: {
  children: ReactNode;
  afterAuth: (userData: UserModel) => void;
}) => {
  const [authData, setAuthData] = useState<UserModel>();
  const isAuthorized = !!localStorage.getItem('token');

  useEffect(() => {
    (async () => {
      if (!isAuthorized) {
        return;
      }
      const user = await apiClient.userAuth.userAuthControllerMe();

      if (user) {
        setAuthData(user);
        afterAuth(user);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value: AuthContextValue = {
    authData,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('AuthContext must be used within a Provider');
  }

  return context;
};

export default useAuth;
