/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InvoiceItemModel = {
    createdAt: string;
    updatedAt: string;
    type: InvoiceItemModel.type;
    name: string;
    code: string;
    areaCode: string | null;
    areaName: string | null;
    quantity: number;
    unit: string;
    factor: number;
    pricePerUnit: number;
    price: number;
    vatPercent: number;
    status: InvoiceItemModel.status;
    invoiceId: string;
    id: string;
};

export namespace InvoiceItemModel {

    export enum type {
        SERVICE = 'service',
        PRODUCT = 'product',
        MEDICATION = 'medication',
        FOOD = 'food',
    }

    export enum status {
        APPLIED = 'applied',
        DISPENSED = 'dispensed',
    }


}

