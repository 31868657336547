/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AuthoriseByHashDto = {
    hash: string;
    type: AuthoriseByHashDto.type;
};

export namespace AuthoriseByHashDto {

    export enum type {
        PHONE_NUMBER = 'phone_number',
        EMAIL = 'email',
    }


}

