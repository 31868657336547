/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileModel } from '../models/FileModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FileService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns FileModel
     * @throws ApiError
     */
    public fileControllerUploadFile(): CancelablePromise<FileModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/file/upload',
        });
    }

}
