import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import _OtpInput from 'react18-input-otp';
import classNames from 'classnames';
import { Trans } from 'react-i18next';

import styles from './OtpInput.module.scss';
import { useEffectOnce } from 'react-use';

export type OtpInputProps = {
  value?: string;
  setValue: (value: string) => void;
  onFill?: (code: string) => void;
  length?: number;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  errorKey?: string;
  autoFocus?: boolean;
};

export type OtpInputRef = {
  focusLatestInput: () => void;
};

export const OtpInput = React.forwardRef<OtpInputRef, OtpInputProps>(
  (
    {
      value,
      setValue,
      length = 6,
      onFill = () => {},
      type,
      errorKey,
      autoFocus,
    },
    ref,
  ) => {
    const otpRef = useRef<_OtpInput>(null);

    useImperativeHandle(ref, () => ({
      focusLatestInput: () => {
        const inputIndex = value
          ? value.length === length
            ? length - 1
            : value.length
          : 0;
        otpRef.current?.focusInput(inputIndex);
      },
    }));

    useEffect(() => {
      if (value && value.length === length) {
        onFill(value);
      }
    }, [value, length, onFill]);

    const handleOnKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace' && value) {
          setValue(value.slice(0, value.length - 1));
          otpRef?.current?.focusInput(value.length - 1);
        }
      },
      [setValue, value],
    );

    useEffectOnce(() => {
      if (autoFocus) {
        setTimeout(() => {
          otpRef?.current?.focusInput(1);
        });
      }
    });
    return (
      <div className={styles.wrapper}>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <_OtpInput
          ref={otpRef}
          value={value}
          onChange={setValue}
          numInputs={length}
          containerStyle={styles.container}
          isInputNum
          inputStyle={classNames(
            styles.input,
            errorKey ? styles.invalidCode : null,
          )}
          shouldAutoFocus={autoFocus}
          inputProps={{
            autoComplete: 'one-time-code',
            inputMode: 'numeric',
            onKeyDown: handleOnKeyDown,
            type,
          }}
        />
        {errorKey || true ? (
          <span className={styles.error}>
            <Trans i18nKey={errorKey as any} values={{ length }} />
          </span>
        ) : null}
      </div>
    );
  },
);
