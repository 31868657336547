import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';

import { apiClient, ApiError } from 'service/api';
import classNames from 'classnames';
import styles from './ForgotPassword.module.scss';
import { Button as NewButton } from 'components/Button';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'components/Alert';

export const ForgotPassword = () => {
  const [t] = useTranslation();
  const { alert, successAlert } = useAlert();

  const history = useHistory();

  const [email, setEmail] = useState<string>('');

  const handleSubmit = async () => {
    try {
      await apiClient.userAuth.userAuthControllerResetCredentials({
        requestBody: {
          email,
        },
      });

      successAlert.open({
        content: t('password_reset_triggered'),
      });
    } catch (error) {
      if (error instanceof ApiError) {
        alert.open({
          content: error.body.message,
        });
        return;
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.contentContainer)}>
        <h1 className={styles.title}>
          <Trans
            i18nKey={'forgot_password_title'}
            components={{
              HIGHLIGHT: <span className={styles.hightlight} />,
            }}
          />
        </h1>
        <span className={styles.description}>
          {t('forgot_password_description')}
        </span>
        <Form
          className={styles.form}
          layout="vertical"
          onSubmitCapture={handleSubmit}>
          <Form.Item label={<span>{t('email')}</span>}>
            <Input
              size={'large'}
              name="email"
              placeholder={t('email')}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onPressEnter={() => {
                handleSubmit();
              }}
            />
          </Form.Item>
        </Form>
        <div className={styles.controls}>
          <NewButton type="secondary" onClick={() => history.push('/login')}>
            {t('back')}
          </NewButton>
          <NewButton disabled={!email} onClick={() => handleSubmit()}>
            {t('submit')}
          </NewButton>
        </div>
      </div>
    </div>
  );
};
