import * as Sentry from '@sentry/react';
import { Replay } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export function initSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN || process.env.SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [new BrowserTracing(), new Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  });
}

export function logError(error: string, errorInfo = null) {
  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}
