/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerModel } from './CustomerModel';

export type CustomerAttributionModel = {
    customerId: string;
    attribution: CustomerAttributionModel.attribution;
    otherAttributionComment?: string;
    createdAt: string;
    updatedAt: string;
    customer: CustomerModel;
    id: string;
};

export namespace CustomerAttributionModel {

    export enum attribution {
        FRIENDS_FAMILY = 'friends_family',
        GOOGLE = 'google',
        INSTAGRAM = 'instagram',
        TIKTOK = 'tiktok',
        FACEBOOK = 'facebook',
        NEBENAN = 'nebenan',
        NEWSPAPER = 'newspaper',
        FYLER = 'fyler',
        POSTER = 'poster',
        OTHER = 'other',
        NEIGHBOURHOOD_WALKED_BY = 'neighbourhood_walked_by',
        RECOMMENDATION_FROM_VET = 'recommendation_from_vet',
        GOOGLE_MAPS = 'google_maps',
    }


}

