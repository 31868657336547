/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthoriseByHashDto } from '../models/AuthoriseByHashDto';
import type { AuthoriseByHashRo } from '../models/AuthoriseByHashRo';
import type { AuthorizeByOtpDto } from '../models/AuthorizeByOtpDto';
import type { AuthorizeByOtpRO } from '../models/AuthorizeByOtpRO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomerUnauthenticatedService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns AuthoriseByHashRo
     * @throws ApiError
     */
    public customerUnauthControllerAuthoriseByHash({
        requestBody,
    }: {
        requestBody: AuthoriseByHashDto,
    }): CancelablePromise<AuthoriseByHashRo> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/customer/unauth/authorisation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AuthorizeByOtpRO
     * @throws ApiError
     */
    public customerUnauthControllerAuthoriseByOtp({
        requestBody,
    }: {
        requestBody: AuthorizeByOtpDto,
    }): CancelablePromise<AuthorizeByOtpRO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/customer/unauth/authorisation-by-otp',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
