/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateVerificationDto = {
    type?: CreateVerificationDto.type;
};

export namespace CreateVerificationDto {

    export enum type {
        PHONE_NUMBER = 'phone_number',
        EMAIL = 'email',
    }


}

