/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetFullVersionUrlRo } from '../models/GetFullVersionUrlRo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ShortenedUrlService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns GetFullVersionUrlRo
     * @throws ApiError
     */
    public shortenedUrlControllerGetFullVersionUrl({
        code,
    }: {
        code: string,
    }): CancelablePromise<GetFullVersionUrlRo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/url/full-version',
            query: {
                'code': code,
            },
        });
    }

}
