import React from 'react';
import { ReadOutlined } from '@ant-design/icons';

import { lazyRetry } from '../AppMenu/utils/lazyRetry';
import menuData, { MenuItem, roles } from 'components/AppMenu/MenuData';

const AppointmentCalendar = lazyRetry(
  () => import('pages/Appointment/Calendar'),
);
const Appointment = lazyRetry(() => import('pages/Appointment'));

const CustomerPage = lazyRetry(() => import('pages/Customer'));

export const headerRoutes: MenuItem[] = [
  {
    key: 'calendar',
    name: 'appointments',
    path: '/m/calendar',
    icon: <ReadOutlined />,
    exact: true,
    component: AppointmentCalendar,
    children: [
      {
        key: 'appointments-list',
        name: 'list',
        path: '/m/appointments-list',
        icon: <ReadOutlined />,
        exact: true,
        component: Appointment,
      },
      {
        key: 'calendar',
        name: 'calendar',
        path: '/m/calendar',
        icon: <ReadOutlined />,
        exact: true,
        component: AppointmentCalendar,
      },
    ],
  },
  {
    key: 'customers-list',
    name: 'customers',
    path: '/m/customers-list',
    exact: true,
    component: CustomerPage,
  },
];
