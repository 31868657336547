/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreatePetDto = {
    name: string;
    breed?: string;
    gender?: CreatePetDto.gender;
    birthDate?: string;
    categoryId: string;
    ownerId: string;
    hasInsurance?: boolean;
    isNeutered?: boolean;
    isOutdoor?: boolean;
    alergyAndDisease?: string;
    medication?: string;
    chipNumber?: string;
    euPassNumber?: string;
    insuranceProvider?: string;
    insuranceIdNumber?: string;
    notes?: string;
    isGermanOrigin?: boolean;
    originCountry?: string | null;
};

export namespace CreatePetDto {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}

