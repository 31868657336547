/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnonymiseCustomerDto } from '../models/AnonymiseCustomerDto';
import type { ByCustomerIdDto } from '../models/ByCustomerIdDto';
import type { CustomerModel } from '../models/CustomerModel';
import type { CustomerResetPasswordbyAdminDto } from '../models/CustomerResetPasswordbyAdminDto';
import type { CustomerResetPasswordbyAdminRo } from '../models/CustomerResetPasswordbyAdminRo';
import type { CustomerStatsRo } from '../models/CustomerStatsRo';
import type { CustomerWithPetDto } from '../models/CustomerWithPetDto';
import type { FilterCustomersDto } from '../models/FilterCustomersDto';
import type { RequestCompleteProfileDto } from '../models/RequestCompleteProfileDto';
import type { UpdateBlockedCustomerDto } from '../models/UpdateBlockedCustomerDto';
import type { UpdateCustomerAdminDto } from '../models/UpdateCustomerAdminDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomerAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns CustomerModel
     * @throws ApiError
     */
    public customerAdminControllerGetAll({
        query,
        limit,
    }: {
        query?: string,
        limit?: number,
    }): CancelablePromise<Array<CustomerModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/admin/customer',
            query: {
                'query': query,
                'limit': limit,
            },
        });
    }

    /**
     * @returns CustomerModel
     * @throws ApiError
     */
    public customerAdminControllerUpdate({
        requestBody,
    }: {
        requestBody: UpdateCustomerAdminDto,
    }): CancelablePromise<CustomerModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/admin/customer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomerModel
     * @throws ApiError
     */
    public customerAdminControllerGetAllFiltered({
        requestBody,
    }: {
        requestBody: FilterCustomersDto,
    }): CancelablePromise<Array<CustomerModel>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/customer/filter',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomerModel
     * @throws ApiError
     */
    public customerAdminControllerGetOne({
        id,
    }: {
        id: string,
    }): CancelablePromise<CustomerModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/admin/customer/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CustomerModel
     * @throws ApiError
     */
    public customerAdminControllerUpdateBlocked({
        requestBody,
    }: {
        requestBody: UpdateBlockedCustomerDto,
    }): CancelablePromise<CustomerModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/customer/update/blocked',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomerModel
     * @throws ApiError
     */
    public customerAdminControllerCreateCustomerWithPet({
        requestBody,
    }: {
        requestBody: CustomerWithPetDto,
    }): CancelablePromise<CustomerModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/customer/with-pet',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public customerAdminControllerRequestCompleteProfile({
        requestBody,
    }: {
        requestBody: RequestCompleteProfileDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/customer/request-complete-profile',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomerResetPasswordbyAdminRo
     * @throws ApiError
     */
    public customerAdminControllerForgotPassword({
        requestBody,
    }: {
        requestBody: CustomerResetPasswordbyAdminDto,
    }): CancelablePromise<CustomerResetPasswordbyAdminRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/customer/reset-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomerModel
     * @throws ApiError
     */
    public customerAdminControllerDeleteCustomer({
        requestBody,
    }: {
        requestBody: AnonymiseCustomerDto,
    }): CancelablePromise<CustomerModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/customer/anonymise',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomerStatsRo
     * @throws ApiError
     */
    public customerAdminControllerGetStatistics({
        requestBody,
    }: {
        requestBody: ByCustomerIdDto,
    }): CancelablePromise<CustomerStatsRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/customer/statistics',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
