/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePetDto } from '../models/CreatePetDto';
import type { PetModel } from '../models/PetModel';
import type { UpdateDeceasedPetDto } from '../models/UpdateDeceasedPetDto';
import type { UpdateDeletedPetDto } from '../models/UpdateDeletedPetDto';
import type { UpdatePetDto } from '../models/UpdatePetDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PetAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PetModel
     * @throws ApiError
     */
    public petAdminControllerSavePet({
        requestBody,
    }: {
        requestBody: CreatePetDto,
    }): CancelablePromise<PetModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/pet/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PetModel
     * @throws ApiError
     */
    public petAdminControllerUpdatePet({
        requestBody,
    }: {
        requestBody: UpdatePetDto,
    }): CancelablePromise<PetModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/pet/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PetModel
     * @throws ApiError
     */
    public petAdminControllerUpdateDeceased({
        requestBody,
    }: {
        requestBody: UpdateDeceasedPetDto,
    }): CancelablePromise<PetModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/pet/update/deceased',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PetModel
     * @throws ApiError
     */
    public petAdminControllerUpdateDeleted({
        requestBody,
    }: {
        requestBody: UpdateDeletedPetDto,
    }): CancelablePromise<PetModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/pet/update/deleted',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PetModel
     * @throws ApiError
     */
    public petAdminControllerGetPetById({
        id,
    }: {
        id: string,
    }): CancelablePromise<PetModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/admin/pet',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @returns PetModel
     * @throws ApiError
     */
    public petAdminControllerGetPetsByCustomer({
        customerId,
    }: {
        customerId: string,
    }): CancelablePromise<Array<PetModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/admin/pet/by-customer',
            query: {
                'customerId': customerId,
            },
        });
    }

}
