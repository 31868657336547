/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetClinicAvailableDaysForClientDto } from '../models/GetClinicAvailableDaysForClientDto';
import type { GetClinicAvailableDaysForClientRo } from '../models/GetClinicAvailableDaysForClientRo';
import type { GetClinicAvailableHoursForClientDto } from '../models/GetClinicAvailableHoursForClientDto';
import type { GetClinicAvailableHoursForClientRo } from '../models/GetClinicAvailableHoursForClientRo';
import type { GetDefaultDaysRangeRo } from '../models/GetDefaultDaysRangeRo';
import type { GetDoctorsWithTreatmentForClientDto } from '../models/GetDoctorsWithTreatmentForClientDto';
import type { GetDoctorsWithTreatmentForClientRo } from '../models/GetDoctorsWithTreatmentForClientRo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AvailabilityUnauthenticatedService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns GetDefaultDaysRangeRo
     * @throws ApiError
     */
    public availabilityUnauthControllerGetDefaultDaysRange(): CancelablePromise<GetDefaultDaysRangeRo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/unauth/availability/default/days-range',
        });
    }

    /**
     * @returns GetClinicAvailableHoursForClientRo
     * @throws ApiError
     */
    public availabilityUnauthControllerGetClinicAvailableHours({
        requestBody,
    }: {
        requestBody: GetClinicAvailableHoursForClientDto,
    }): CancelablePromise<GetClinicAvailableHoursForClientRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/unauth/availability/clinic/available-hours',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetClinicAvailableDaysForClientRo
     * @throws ApiError
     */
    public availabilityUnauthControllerGetClinicAvailableDays({
        requestBody,
    }: {
        requestBody: GetClinicAvailableDaysForClientDto,
    }): CancelablePromise<GetClinicAvailableDaysForClientRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/unauth/availability/clinic/available-days',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetDoctorsWithTreatmentForClientRo
     * @throws ApiError
     */
    public availabilityUnauthControllerGetDoctorsByTreatmentStatus({
        requestBody,
    }: {
        requestBody: GetDoctorsWithTreatmentForClientDto,
    }): CancelablePromise<GetDoctorsWithTreatmentForClientRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/unauth/availability/doctor/with-treatment-status',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
