import { LocaleValue } from './common';
import { AppointmentModel } from 'service/api';

export enum AppointmentWarning {
  TREATMENT_IS_LATE = 'treatment_is_late',
  CHECKOUT_IS_LATE = 'checkout_is_late',
}

export type AppointmentDoctor = {
  id: string;
  email: string;
  name: string;
};

export type AppointmentFile = {
  filename: string;
  id: string;
  location: string;
};
