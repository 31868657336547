/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PetCategoryModel } from './PetCategoryModel';
import type { TreatmentModel } from './TreatmentModel';

export type MedicalEventRuleModel = {
    createdAt: string;
    updatedAt: string;
    name: any;
    description: any;
    petCategoryId: string;
    isOutdoorCat: boolean | null;
    type: MedicalEventRuleModel.type;
    triggerType: MedicalEventRuleModel.triggerType;
    triggerValue: string;
    intervalInMonths: number;
    treatmentId: string;
    isActive: boolean;
    picture: string;
    petCategory: PetCategoryModel;
    treatment: TreatmentModel;
    isGeriatric: boolean;
    id: string;
};

export namespace MedicalEventRuleModel {

    export enum type {
        PREVENTION = 'prevention',
        EVENT_BASED = 'event_based',
        INTERNAL_EVENT_BASED = 'internal_event_based',
    }

    export enum triggerType {
        VISIT_ITEM_CODE = 'visit_item_code',
        VISIT_ITEM_NAME = 'visit_item_name',
        PET_AGE = 'pet_age',
    }


}

