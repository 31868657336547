/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FaqDataJsonModel } from '../models/FaqDataJsonModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StaticService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns string
     * @throws ApiError
     */
    public staticControllerGetLanguages(): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/static/languages',
        });
    }

    /**
     * @returns FaqDataJsonModel
     * @throws ApiError
     */
    public staticControllerGetFaqs(): CancelablePromise<FaqDataJsonModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/static/faqs',
        });
    }

}
