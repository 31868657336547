/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppointmentModel } from '../models/AppointmentModel';
import type { LockMultipleAppointmentsDto } from '../models/LockMultipleAppointmentsDto';
import type { LockMultipleAppointmentsRo } from '../models/LockMultipleAppointmentsRo';
import type { ReScheduleAppointmentDto } from '../models/ReScheduleAppointmentDto';
import type { ReScheduleAppointmentRo } from '../models/ReScheduleAppointmentRo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AppointmentUnauthenticatedService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns LockMultipleAppointmentsRo
     * @throws ApiError
     */
    public appointmentUnauthControllerLockMultiple({
        requestBody,
    }: {
        requestBody: LockMultipleAppointmentsDto,
    }): CancelablePromise<LockMultipleAppointmentsRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/unauth/lock-multiple',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReScheduleAppointmentRo
     * @throws ApiError
     */
    public appointmentUnauthControllerReScheduleUnAuth({
        requestBody,
    }: {
        requestBody: ReScheduleAppointmentDto,
    }): CancelablePromise<ReScheduleAppointmentRo> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/appointment/unauth/reschedule',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public appointmentUnauthControllerGetIcsForRexApp({
        appointmentId,
        authToken,
    }: {
        appointmentId: string,
        authToken: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/appointment/unauth/ics-for-rexapp/{appointmentId}',
            path: {
                'appointmentId': appointmentId,
            },
            query: {
                'authToken': authToken,
            },
        });
    }

    /**
     * @returns AppointmentModel
     * @throws ApiError
     */
    public appointmentUnauthControllerGetOne({
        appointmentId,
    }: {
        appointmentId: string,
    }): CancelablePromise<AppointmentModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/appointment/unauth/{appointmentId}',
            path: {
                'appointmentId': appointmentId,
            },
        });
    }

}
