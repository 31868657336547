/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckoutTelemedicineAppointmentDto } from '../models/CheckoutTelemedicineAppointmentDto';
import type { CheckoutTelemedicineAppointmentRo } from '../models/CheckoutTelemedicineAppointmentRo';
import type { TelemedicineGetRoomParticipantsRo } from '../models/TelemedicineGetRoomParticipantsRo';
import type { TelemedicineGetTokenDto } from '../models/TelemedicineGetTokenDto';
import type { TelemedicineGetTokenRo } from '../models/TelemedicineGetTokenRo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AppointmentTelemedicineService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns TelemedicineGetTokenRo
     * @throws ApiError
     */
    public appointmentTelemedicineControllerGetTelemedicineToken({
        requestBody,
    }: {
        requestBody: TelemedicineGetTokenDto,
    }): CancelablePromise<TelemedicineGetTokenRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/telemedicine/get-token',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TelemedicineGetRoomParticipantsRo
     * @throws ApiError
     */
    public appointmentTelemedicineControllerGetTelemedicineRoomParticipants({
        appointmentId,
    }: {
        appointmentId: string,
    }): CancelablePromise<TelemedicineGetRoomParticipantsRo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/appointment/telemedicine/get-room-participants',
            query: {
                'appointmentId': appointmentId,
            },
        });
    }

    /**
     * @returns CheckoutTelemedicineAppointmentRo
     * @throws ApiError
     */
    public appointmentTelemedicineControllerFinishTelemedicineAppointmnent({
        requestBody,
    }: {
        requestBody: CheckoutTelemedicineAppointmentDto,
    }): CancelablePromise<CheckoutTelemedicineAppointmentRo> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/appointment/telemedicine/finish',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
