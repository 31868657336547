/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LegalNoticesDto = {
    consentedLegalNotices: Array<string>;
    location: LegalNoticesDto.location;
};

export namespace LegalNoticesDto {

    export enum location {
        BOOKING_FLOW_PARENT_FORM = 'booking_flow_parent_form',
        BOOKING_FLOW_DATE_TIME_FORM = 'booking_flow_date_time_form',
        COMPLETION_FLOW_PARENT_FORM = 'completion_flow_parent_form',
        DASHBOARD_SET_PASSWORD_FORM = 'dashboard_set_password_form',
        DASHBOARD_RESET_PASSWORD_FORM = 'dashboard_reset_password_form',
        FORGOT_PASSWORD_FLOW_RESET_PASSWORD_FORM = 'forgot_password_flow_reset_password_form',
        BOOKING_FLOW_PAYMENT_FORM = 'booking_flow_payment_form',
        APP_BOOKING_FLOW_PARENT_FORM = 'app_booking_flow_parent_form',
        APP_BOOKING_FLOW_DATE_TIME_FORM = 'app_booking_flow_date_time_form',
        APP_COMPLETION_FLOW_PARENT_FORM = 'app_completion_flow_parent_form',
        APP_DASHBOARD_SET_PASSWORD_FORM = 'app_dashboard_set_password_form',
        APP_DASHBOARD_RESET_PASSWORD_FORM = 'app_dashboard_reset_password_form',
        APP_FORGOT_PASSWORD_FLOW_RESET_PASSWORD_FORM = 'app_forgot_password_flow_reset_password_form',
        APP_BOOKING_FLOW_PAYMENT_FORM = 'app_booking_flow_payment_form',
    }


}

