import { ComponentType, LazyExoticComponent, lazy } from 'react';

type ComponentPromise<T = any> = Promise<{ default: ComponentType<T> }>;

export const lazyRetry = (
  component: () => ComponentPromise,
): LazyExoticComponent<ComponentType<any>> => {
  return lazy(() => retry(component));
};

const retry = (componentImport: () => ComponentPromise): ComponentPromise => {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    );

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
          return window.location.reload();
        }
        reject(error);
      });
  });
};
