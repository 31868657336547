import React from 'react';
import { useFormik } from 'formik';
import { TFuncKey, Trans, useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import * as Yup from 'yup';

import { apiClient, ApiError } from 'service/api';
import classNames from 'classnames';
import styles from './Login.module.scss';
import { Button as NewButton } from 'components/Button';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'components/Alert';

const validationSchema = Yup.object({
  email: Yup.string().required('validation_required'),
  password: Yup.string().required('validation_required'),
});

export type LoginData = Yup.InferType<typeof validationSchema>;

export const Login = () => {
  const [t] = useTranslation();
  const { alert } = useAlert();

  const history = useHistory();

  const onForgotPassword = () => {
    history.push('/forgot-password');
  };

  const {
    values: formValues,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    setSubmitting,
    errors,
  } = useFormik<LoginData>({
    initialValues: { email: '', password: '' },
    validationSchema: validationSchema,
    onSubmit: async ({ password, email }) => {
      const data = { password, email: email };
      setSubmitting(true);

      try {
        const res = await apiClient.userAuth.userAuthControllerSignIn({
          requestBody: {
            email: data.email,
            password: data.password,
          },
        });

        localStorage.setItem('token', res.accessToken);
        localStorage.setItem('role', res.user.roles?.[0].role);
        window.location.href = `/m/calendar`;
        setSubmitting(false);
      } catch (error) {
        if (error instanceof ApiError) {
          alert.open({
            content: error.body.message,
          });
          return;
        }
      }
    },
  });

  const getValidationProps = (
    errorMessage?: string,
    options?: any,
  ): { help?: string; validateStatus: 'error' | 'success' } => {
    return {
      help: errorMessage && t(errorMessage as TFuncKey, options),
      validateStatus: typeof errorMessage === 'string' ? 'error' : 'success',
    };
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.contentContainer)}>
        <h1 className={styles.title}>
          <Trans
            i18nKey={'welcome_to_rexos'}
            components={{
              HIGHLIGHT: <span className={styles.hightlight} />,
            }}
          />
        </h1>
        <Form
          className={styles.form}
          layout="vertical"
          onSubmitCapture={handleSubmit}>
          <Form.Item label={t('email')} {...getValidationProps(errors.email)}>
            <Input
              size={'large'}
              name="email"
              placeholder={t('email')}
              value={formValues['email']}
              onChange={(e) => {
                setFieldValue('email', e.target.value);
              }}
              onPressEnter={() => {
                handleSubmit();
              }}
            />
          </Form.Item>

          <Form.Item
            label={t('password')}
            {...getValidationProps(errors.password)}>
            <Input.Password
              size={'large'}
              name="password"
              placeholder={t('password')}
              value={formValues['password']}
              onChange={(e) => {
                setFieldValue('password', e.target.value);
              }}
              onPressEnter={() => {
                handleSubmit();
              }}
            />
          </Form.Item>
        </Form>
        <span className={styles.forgotPassword} onClick={onForgotPassword}>
          {t('forgot_password')}
        </span>
        <NewButton
          disabled={
            isSubmitting ||
            !!Object.keys(errors).length ||
            !Object.values(formValues).some((e) => !!e)
          }
          onClick={() => handleSubmit()}>
          {t('login')}
        </NewButton>
      </div>
    </div>
  );
};
