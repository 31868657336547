/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RemindMedicalEventsRo } from '../models/RemindMedicalEventsRo';
import type { TriggerUpdateDto } from '../models/TriggerUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MedicalEventTriggerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public medicalEventTriggerControllerHandlePetUpdate({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/medical-event/trigger/event/pet/update-medical-events',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public medicalEventTriggerControllerHandleVisit({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/medical-event/trigger/event/visit/update-medical-events',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RemindMedicalEventsRo
     * @throws ApiError
     */
    public medicalEventTriggerControllerRemindMedicalEvents({
        xSecretHeader,
    }: {
        /**
         * Secret
         */
        xSecretHeader: string,
    }): CancelablePromise<RemindMedicalEventsRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/medical-event/trigger/cron/remind-this-month',
            headers: {
                'x-secret-header': xSecretHeader,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public medicalEventTriggerControllerCreateInitialScript({
        xSecretHeader,
    }: {
        /**
         * Secret
         */
        xSecretHeader: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/medical-event/trigger/event/create-initial-script',
            headers: {
                'x-secret-header': xSecretHeader,
            },
        });
    }

}
