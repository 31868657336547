import React, { useEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { PinVerificationModal } from './components/PinVerificationModal/PinVerificationModal';
import jwtDecode from 'jwt-decode';

export const useAuthenticationWindow = (numberOfSeconds: number) => {
  const idleSinceTimeStamp = useRef<number>();

  const getIsIdle = () => {
    const isAuthorized = !!localStorage.getItem('token');
    const idleSince = idleSinceTimeStamp?.current;
    if (!isAuthorized || !idleSince) {
      return false;
    }
    const isIdle = new Date().getTime() - idleSince > numberOfSeconds * 1000;
    return isIdle;
  };

  const [isPinModalOpen, setIsPinModalOpen] = useState<boolean>(
    !!localStorage.getItem('token') &&
      jwtDecode<{ id: string }>(localStorage.getItem('token') as string)?.id ===
        localStorage.getItem('isPinModalOpen'),
  );

  const configureAndVerify = () => {
    const isIdle = getIsIdle();
    if (!isIdle) {
      return;
    }
    setIsPinModalOpen(true);
  };

  const resetIdleTime = () => {
    idleSinceTimeStamp.current = new Date().getTime();
    unsetEvents();
    setTimeout(setEvents, 1000);
  };

  const setEvents = () => {
    document.addEventListener('mousemove', resetIdleTime);
    document.addEventListener('keydown', resetIdleTime);
  };

  const unsetEvents = () => {
    document.removeEventListener('mousemove', resetIdleTime);
    document.removeEventListener('keydown', resetIdleTime);
  };

  useEffect(() => {
    if (isPinModalOpen) {
      const currentUserId = jwtDecode<{ id: string }>(
        localStorage.getItem('token') as string,
      )?.id;
      localStorage.setItem('isPinModalOpen', currentUserId);
    } else {
      localStorage.removeItem('isPinModalOpen');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPinModalOpen]);

  useEffectOnce(() => {
    setEvents();
    setInterval(() => {
      configureAndVerify();
    }, 1000);
  });

  return {
    PinVerificationModal: isPinModalOpen
      ? () => (
          <PinVerificationModal isOpen={true} setIsOpen={setIsPinModalOpen} />
        )
      : () => null,
    setIsPinModalOpen,
  };
};
