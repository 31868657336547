/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppointmentModel } from './AppointmentModel';

export type PaymentTransactionModel = {
    amount?: number;
    currencyIsoCode?: string;
    type: PaymentTransactionModel.type;
    transactionId?: string;
    createdAt: string;
    gatewayRejectionReason?: string;
    status: PaymentTransactionModel.status;
    cardLast4?: number;
    merchantAccountId?: string;
    appointmentId: string;
    paymentInstrumentType?: string;
    appointment?: AppointmentModel;
    id: string;
};

export namespace PaymentTransactionModel {

    export enum type {
        PAYMENT = 'payment',
        PAYMENT_OFF_SESSION = 'payment_off_session',
        SETUP = 'setup',
    }

    export enum status {
        PENDING = 'pending',
        APPROVED = 'approved',
        FAILED = 'failed',
        REQUIRES_ACTION = 'requires_action',
        REFUNDED = 'refunded',
    }


}

