/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppVersionModel = {
    createdAt: string;
    updatedAt: string;
    isActive?: boolean;
    shouldForceUpdate?: boolean;
    version: number;
    message?: any;
    shouldPromptUser?: boolean;
    platform: AppVersionModel.platform;
    id: string;
};

export namespace AppVersionModel {

    export enum platform {
        IOS = 'ios',
        ANDROID = 'android',
    }


}

