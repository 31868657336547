import Api, { baseUrlV2 } from 'utils/Api';

import { Clinic } from 'types';

export const getClinics = async () => {
  const res = await Api.get<Clinic[]>(`${baseUrlV2}/admin/clinic`);
  return res.data;
};

export const getClinicsOptions = (clinics: Clinic[]) => {
  return clinics.map((d) => ({ value: d.id, text: d.name, data: d }));
};
