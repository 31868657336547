import { AxiosError } from 'axios';

import { ApiError } from './generated';

import { logout } from 'utils';
import { unauthorizedRoutes } from 'Routes';

export const responseErrorHandler = (error: AxiosError | ApiError) => {
  console.error(error);

  let statusCode: number;

  if ('status' in error) {
    statusCode = error.status;
  } else {
    statusCode = error?.request?.status;
  }

  if (
    statusCode === 401 &&
    !unauthorizedRoutes.includes(window.location.pathname)
  ) {
    logout();
  }
};
