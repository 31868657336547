import React from 'react';
import { useTranslation } from 'react-i18next';

import { TaskModal } from '../TaskModal';
import { TaskModel } from 'service/api';
import { TaskForm } from '../TaskForm';

type UserFormModalProps = {
  taskId?: string;
  onSave: (updatedTask: TaskModel) => void;
  onClose: (shouldRefreshData?: boolean) => void;
  onDirtyChange: (dirty: boolean) => void;
};

export type onCloseArgs = {
  requestingBack?: boolean;
};

export const TaskFormModal = ({
  taskId,
  onSave,
  onClose,
  onDirtyChange,
}: UserFormModalProps) => {
  const { t } = useTranslation();

  const title = taskId ? t('edit') : t('add_new');

  return (
    <TaskModal title={title} onClose={onClose}>
      <TaskForm
        taskId={taskId}
        onDirtyChange={onDirtyChange}
        onSave={onSave}
        onCancel={onClose}
      />
    </TaskModal>
  );
};
