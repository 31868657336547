import { useCallback, useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import {
  apiClient,
  CustomerModel,
  PetModel,
  TaskModel,
  UserModel,
} from 'service/api';

type FetchDataParams = {
  taskId?: string;
};

export const useFetchTaskData = (initialParams: FetchDataParams) => {
  const [customers, setCustomers] = useState<CustomerModel[]>([]);

  const [users, setUsers] = useState<UserModel[]>();

  const fetchCustomers = useCallback(async (query: string = '') => {
    const customers =
      await apiClient.customerAdmin.customerAdminControllerGetAll({
        query,
        limit: 15,
      });

    setCustomers(customers);

    return customers;
  }, []);

  const [{ value: pets }, fetchPets] = useAsyncFn(
    async ({ customerId }: { customerId: string }) => {
      return await apiClient.petAdmin.petAdminControllerGetPetsByCustomer({
        customerId,
      });
    },
  );

  const fetchUsers = async () => {
    const usersData = await apiClient.userAdmin.userAdminControllerGetAll({
      requestBody: {},
    });

    setUsers(usersData);

    return usersData;
  };

  const [{ value: data, loading: isLoading }, fetchData] = useAsyncFn(
    async () => {
      const promises: [Promise<CustomerModel[]>, Promise<TaskModel>?] = [
        fetchCustomers(),
      ];

      if (initialParams.taskId) {
        promises.push(
          apiClient.taskAdmin.taskAdminControllerGetOne({
            id: initialParams.taskId,
          }),
        );
      }

      const [, task] = await Promise.all(promises);
      const additionalDataPromises: [
        Promise<UserModel[]>?,
        Promise<PetModel[]>?,
      ] = [fetchUsers()];

      if (task?.customerId) {
        additionalDataPromises.push(
          fetchPets({ customerId: task?.customerId }),
        );
      }

      await Promise.all(additionalDataPromises);
      return { task };
    },
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...data,
    customers,
    pets,
    users,
    isLoading,
    fetchCustomers,
    fetchPets,
  };
};
