/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TriggerAppointmentEventDto = {
    appointmentId: string;
    event: TriggerAppointmentEventDto.event;
};

export namespace TriggerAppointmentEventDto {

    export enum event {
        TEAM_MEMBER_CREATED = 'team_member_created',
        TEAM_MEMBER_CONFIRMED = 'team_member_confirmed',
        TEAM_MEMBER_MARKED_AS_MISSED = 'team_member_marked_as_missed',
        TEAM_MEMBER_CANCELLED = 'team_member_cancelled',
        TEAM_MEMBER_UPDATED = 'team_member_updated',
        TEAM_MEMBER_MARKED_AS_WAITING = 'team_member_marked_as_waiting',
        TEAM_MEMBER_STARTED_TREATMENT = 'team_member_started_treatment',
        TEAM_MEMBER_FINISHED_TREATMENT = 'team_member_finished_treatment',
        TEAM_MEMBER_COMPLETED = 'team_member_completed',
    }


}

