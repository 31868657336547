import { useCallback, useEffect } from 'react';
import { useAsyncRetry } from 'react-use';
import moment from 'moment-timezone';
import { TFuncKey, useTranslation } from 'react-i18next';

import { AppointmentWarning } from 'types';
import { apiClient, AppointmentModel } from 'service/api';
import useApp from 'store/AppContext';

const INTERVAL = 15 * 60 * 1000; // 15 mins

const WARNINGS_WITH_NOTIFICATION: AppointmentModel['warnings'] = [
  AppointmentWarning.TREATMENT_IS_LATE,
];

export const useAppointmentWarnings = () => {
  const [t] = useTranslation();
  const { data: appData } = useApp();

  const { value: appointments, retry } = useAsyncRetry(() => {
    const isAuthorized =
      !!localStorage.getItem('token') && !!localStorage.getItem('role');
    if (!isAuthorized) {
      return new Promise<AppointmentModel[]>(() => []);
    }
    return apiClient.appointmentAdmin.appointmentAdminControllerGetAll({
      requestBody: {
        from: moment().startOf('day').format(),
        to: moment().endOf('day').format(),
        type: AppointmentModel.type.APPOINTMENT,
        statuses: [AppointmentModel.status.APPROVED],
        clinicId: appData.clinic,
      },
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(retry, INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [retry]);

  const createNotifications = useCallback(() => {
    (appointments || []).forEach((appointment) => {
      const { pet, customer } = appointment;
      const redirectUrl = `/m/calendar?appointmentId=${appointment.id}`;

      appointment.warnings
        .filter((warning) => WARNINGS_WITH_NOTIFICATION.includes(warning))
        .forEach((warning) => {
          const notification = new Notification(
            t('update_appointment_status'),
            {
              icon: '/logo192.png',
              body: t(warning as TFuncKey, {
                customer: [customer?.firstName, customer?.lastName].join(' '),
                pet: pet?.name,
              }),
              tag: appointment.id,
              requireInteraction: true,
            },
          );

          notification.addEventListener('click', () => {
            window.parent.parent.focus();
            window.location.href = redirectUrl;
          });
        });
    });
  }, [t, appointments]);

  useEffect(() => {
    if (!('Notification' in window)) {
      return;
    }

    if (Notification.permission === 'granted') {
      createNotifications();
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          createNotifications();
        }
      });
    }
  }, [createNotifications, appointments]);
};
