import React from 'react';
import { isDayDisabled } from '../../utils';
import moment from 'moment-timezone';

type CalendarDayProps = {
  date: moment.Moment;
  availableDays?: string[];
};

export const CalendarDay = ({ date, availableDays }: CalendarDayProps) => {
  const style: React.CSSProperties = {};

  if (isDayDisabled(date, availableDays)) {
    style.color = '#e75d58';
  }

  return (
    <div className="ant-picker-cell-inner" style={style}>
      {date.date()}
    </div>
  );
};
