import { MenuItem } from '../MenuData';

export const joinPathnames = (menuItems: MenuItem[]) => {
  let pathnames: string[] = [];
  for (const menuItem of menuItems) {
    if (menuItem.path) {
      pathnames.push(menuItem.path);
    }
    if (menuItem.children) {
      pathnames = [...pathnames, ...joinPathnames(menuItem.children)];
    }
  }
  return [...new Set(pathnames)];
};
