import React from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import styles from './LanguageSwitcher.module.scss';

export const LanguageSwitcher = () => {
  const localesOrdered = ['en', 'de'];

  const { i18n } = useTranslation();

  return (
    <ul className={styles.switcher}>
      {(localesOrdered || []).map((locale) => (
        <li key={locale}>
          <button
            className={classNames(styles.button, {
              [styles.buttonActive]: i18n.language === locale,
            })}
            type="button"
            onClick={() => i18n.changeLanguage(locale)}>
            {locale}
          </button>
        </li>
      ))}
    </ul>
  );
};
