/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateCustomerDto = {
    firstName: string;
    lastName: string;
    email?: string;
    phoneNumber?: string;
    refusedEmail?: boolean;
    landlineNumber?: string;
    source: CreateCustomerDto.source;
    language?: CreateCustomerDto.language;
};

export namespace CreateCustomerDto {

    export enum source {
        MYREX = 'myrex',
        REXOS = 'rexos',
        WALKIN = 'walkin',
        MYREX_WALKIN = 'myrex_walkin',
        REXAPP = 'rexapp',
        REXAPP_WALKIN = 'rexapp_walkin',
    }

    export enum language {
        EN = 'en',
        DE = 'de',
    }


}

