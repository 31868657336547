import React from 'react';
import moment from 'moment';
import { AppointmentModel, GetDoctorAvailableDurationsRo } from 'service/api';
import { SURGERY_TREATMENT_ID } from './constants';
import styles from '../pages/Appointment/components/AppointmentModal/AppointmentModal.module.scss';

export class HelperFunctions {
  static isSurgery = (treatmentId: string) => {
    return treatmentId === SURGERY_TREATMENT_ID;
  };
}

export const getAppointmentsListBlockDisplay = (
  appointments: AppointmentModel[],
) => {
  return (
    <>
      {appointments.map((appointment) => {
        const isSurgery: boolean = HelperFunctions.isSurgery(
          appointment?.treatmentId,
        );
        const isInternal =
          appointment?.type !== AppointmentModel.type.APPOINTMENT;
        const isPending =
          appointment?.status === AppointmentModel.status.PENDING;
        const isBreak = appointment?.type === AppointmentModel.type.BREAK;
        const isNonCurative =
          appointment?.type === AppointmentModel.type.MEETING;

        let backgroundColor: string = '#292E62';

        if (isInternal) {
          backgroundColor = '#fff7ed';
        }

        if (isInternal && isSurgery) {
          backgroundColor = '#ffdfbc';
        }

        if (isBreak) {
          backgroundColor = '#faa7ac';
        }

        if (isNonCurative) {
          backgroundColor = styles.bgPink;
        }

        if (isPending) {
          backgroundColor = '#d4d2d8';
        }
        return (
          <div
            style={{
              display: 'inline-block',
              width: 12,
              height: 12,
              backgroundColor,
              borderRadius: 2,
              marginLeft: 8,
              border: '2px solid #292E62',
              marginTop: 2,
            }}></div>
        );
      })}
    </>
  );
};

export default function durationsToDurationOptions(
  durationsData?: GetDoctorAvailableDurationsRo['doctorAvailableDurationsWithData'],
) {
  return (
    durationsData?.map(({ duration, appointments }) => {
      return {
        value: duration / 60,
        label: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ display: 'inline-block' }}>
              {moment().startOf('day').add(duration, 'minutes').format('H:mm')}{' '}
            </span>
            {getAppointmentsListBlockDisplay(appointments)}
          </div>
        ),
      };
    }) || []
  );
}
