import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { default as RcTooltip } from 'rc-tooltip';
import { TooltipProps as OgTooltipProps } from 'rc-tooltip/lib/Tooltip';

import styles from './Tooltip.module.scss';

type TooltipProps = {
  children?: ReactNode;
  content: ReactNode;
  visible?: boolean;
  className?: string;
  trigger?: OgTooltipProps['trigger'];
  mouseEnterDelay?: number;
};

export const Tooltip = ({
  children,
  content,
  visible,
  className,
  trigger = 'click',
  mouseEnterDelay,
}: TooltipProps) => {
  return (
    <RcTooltip
      mouseEnterDelay={mouseEnterDelay}
      overlayClassName={classNames(styles.tooltip, className)}
      prefixCls="tooltip"
      placement="bottom"
      trigger={trigger}
      {...(visible === true || visible === false ? { visible } : {})}
      overlay={content}>
      <div>{children}</div>
    </RcTooltip>
  );
};
