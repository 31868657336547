/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CancelAppointmentDto = {
    appointmentId: string;
    statusChangeReason?: CancelAppointmentDto.statusChangeReason;
    statusChangeComment?: string;
};

export namespace CancelAppointmentDto {

    export enum statusChangeReason {
        CUSTOMER_CHOSE_OTHER_CLINIC = 'customer_chose_other_clinic',
        CUSTOMER_PET_GOT_BETTER = 'customer_pet_got_better',
        CUSTOMER_OTHER = 'customer_other',
        CUSTOMER_CANNOT_MAKE_IT_AT_THIS_TIME = 'customer_cannot_make_it_at_this_time',
        TEAM_MEMBER_REX_CANCELED = 'team_member_rex_canceled',
        TEAM_MEMBER_CUSTOMER_CANCELED = 'team_member_customer_canceled',
    }


}

