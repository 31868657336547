import React, { ReactNode, ComponentType } from 'react';

import { ReactComponent as AdminsIcon } from './assets/vetsAndAssistants.svg';
import { ReactComponent as ClinicIcon } from './assets/clinic.svg';
import { ReactComponent as PetCategoriesIcon } from './assets/petCategories.svg';
import { ReactComponent as SkillsIcon } from './assets/skills.svg';
import { ReactComponent as AnalyticsIcon } from './assets/analytics.svg';
import { ReactComponent as CalendarIcon } from './assets/calendar.svg';

import { lazyRetry } from './utils/lazyRetry';

const DoctorPage = lazyRetry(() => import('pages/Doctor'));
const AvailabilitiesCalendar = lazyRetry(
  () => import('pages/Doctor/Availabilities'),
);
const ClinicsTable = lazyRetry(() => import('pages/Clinic/ClinicsTable'));
const ClinicsAnalyticPage = lazyRetry(() => import('pages/Clinic/Analytic'));
const CustomersAnalyticPage = lazyRetry(
  () => import('pages/Customer/Analytic'),
);
const AppointmentsAnalyticPage = lazyRetry(
  () => import('pages/Appointment/Analytic'),
);
const AppointmentsAnalyticsByPetCategory = lazyRetry(
  () => import('pages/Appointment/Analytic/PetCategory'),
);
const AppointmentsAnalyticsByDate = lazyRetry(
  () => import('pages/Appointment/Analytic/Date'),
);
const AppointmentsAnalyticsByInternalHour = lazyRetry(
  () => import('pages/Appointment/Analytic/InternalHour'),
);

const PetCategoriesTable = lazyRetry(
  () => import('pages/PetCategory/PetCategoriesTable'),
);
const UsersTable = lazyRetry(() => import('pages/User/UsersTable'));
const SkillsTable = lazyRetry(() => import('pages/Skill/SkillsTable'));

///
//
// NOTE
//  path property of a menu item should be in the form "/m/X" (except main page).
//  If X is the pathname, then the key property of that menu item should also be X.
//  X should not contain forward slashes.
//

export const roles = Object.freeze({
  Manager: 'manager',
  God: 'god',
  Assistant: 'assistant',
});

export interface MenuItem {
  key: string;
  name: string;
  icon?: ReactNode;
  path?: string;
  component?: ComponentType<() => ReactNode>;
  hidden?: boolean;
  exact?: boolean;
  requiresPermission?: string;
  children?: MenuItem[];
  ignoreChildrenRender?: boolean;
}

const menuData: MenuItem[] = [
  {
    key: 'availabilities-calendar',
    name: 'availabilities_calendar',
    path: '/m/availabilities-calendar',
    exact: true,
    component: AvailabilitiesCalendar,
    icon: <CalendarIcon className="anticon" />,
  },
  {
    key: 'clinics-list',
    name: 'clinics',
    icon: <ClinicIcon className="anticon" />,
    path: '/m/clinics-list',
    exact: true,
    component: ClinicsTable,
  },
  {
    key: 'pet-categories-list',
    name: 'pet_categories',
    icon: <PetCategoriesIcon className="anticon" />,
    requiresPermission: roles.God,
    path: '/m/pet-categories-list',
    exact: true,
    component: PetCategoriesTable,
  },
  {
    key: 'users-list',
    name: 'users',
    icon: <AdminsIcon className="anticon" />,
    requiresPermission: roles.God,
    path: '/m/users-list',
    exact: true,
    component: UsersTable,
  },
  {
    key: 'skills-list',
    name: 'skills',
    icon: <SkillsIcon className="anticon" />,
    requiresPermission: roles.God,
    path: '/m/skills-list',
    exact: true,
    component: SkillsTable,
  },
  // {
  //   key: 'analytics',
  //   name: 'analytics',
  //   icon: <AnalyticsIcon className="anticon" />,
  //   requiresPermission: roles.God,
  //   children: [
  //     {
  //       key: 'clinics-analytic',
  //       name: 'clinics',
  //       requiresPermission: roles.God,
  //       path: '/m/clinics-analytic',
  //       exact: true,
  //       component: ClinicsAnalyticPage,
  //     },
  //     {
  //       key: 'customers-analytic',
  //       name: 'customers',
  //       requiresPermission: roles.God,
  //       path: '/m/customers-analytic',
  //       exact: true,
  //       component: CustomersAnalyticPage,
  //     },
  //     {
  //       key: 'appointments-analytic',
  //       name: 'appointments',
  //       requiresPermission: roles.God,
  //       path: '/m/appointments-analytic',
  //       exact: true,
  //       children: [
  //         {
  //           key: 'appointments-analytic-general',
  //           name: 'appointments-analytic-general',
  //           requiresPermission: roles.God,
  //           path: '/m/appointments-analytic-general',
  //           exact: true,
  //           component: AppointmentsAnalyticPage,
  //         },
  //         {
  //           key: 'appointments-analytic-date',
  //           name: 'appointments-analytic-date',
  //           requiresPermission: roles.God,
  //           path: '/m/appointments-analytic-date',
  //           exact: true,
  //           component: AppointmentsAnalyticsByDate,
  //         },
  //         {
  //           key: 'appointments-analytic-internal',
  //           name: 'appointments-analytic-internal',
  //           requiresPermission: roles.God,
  //           path: '/m/appointments-analytic-internal',
  //           exact: true,
  //           component: AppointmentsAnalyticsByInternalHour,
  //         },
  //         {
  //           key: 'appointments-analytic-pet',
  //           name: 'appointments-analytic-pet',
  //           requiresPermission: roles.God,
  //           path: '/m/appointments-analytic-pet',
  //           exact: true,
  //           component: AppointmentsAnalyticsByPetCategory,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default menuData;
