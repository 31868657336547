/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FilterCustomersDto = {
    fullName?: string;
    email?: string;
    phoneNumber?: string;
    petName?: string;
    chipNumber?: string;
    euPassNumber?: string;
    source?: FilterCustomersDto.source;
    limit?: number;
};

export namespace FilterCustomersDto {

    export enum source {
        MYREX = 'myrex',
        REXOS = 'rexos',
        WALKIN = 'walkin',
        MYREX_WALKIN = 'myrex_walkin',
        REXAPP = 'rexapp',
        REXAPP_WALKIN = 'rexapp_walkin',
    }


}

