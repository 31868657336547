/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { WeeklyScheduleDto } from './WeeklyScheduleDto';

export type UpdateClinicDto = {
    name?: string;
    email?: string;
    phoneNumber?: string;
    status?: UpdateClinicDto.status;
    longitude?: string;
    latitude?: string;
    address?: string;
    mapUrl?: string;
    tZone?: string;
    pictureId?: string;
    weeklySchedule?: WeeklyScheduleDto;
};

export namespace UpdateClinicDto {

    export enum status {
        INACTIVE = 'inactive',
        ACTIVE = 'active',
        PENDING = 'pending',
    }


}

