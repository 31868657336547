/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PetModel } from '../models/PetModel';
import type { UpdatePetDto } from '../models/UpdatePetDto';
import type { UpdatePetImageDto } from '../models/UpdatePetImageDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PetService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PetModel
     * @throws ApiError
     */
    public petControllerUpdatePetImage({
        requestBody,
    }: {
        requestBody: UpdatePetImageDto,
    }): CancelablePromise<PetModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/pet/upload',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PetModel
     * @throws ApiError
     */
    public petControllerUpdatePet({
        requestBody,
    }: {
        requestBody: UpdatePetDto,
    }): CancelablePromise<PetModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/pet',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
