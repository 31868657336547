/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppointmentModel } from '../models/AppointmentModel';
import type { CalendarRO } from '../models/CalendarRO';
import type { CalenderDto } from '../models/CalenderDto';
import type { CancelAppointmentDto } from '../models/CancelAppointmentDto';
import type { GetAdminAppointmentListDto } from '../models/GetAdminAppointmentListDto';
import type { GetAppointmentExtendedRo } from '../models/GetAppointmentExtendedRo';
import type { GetByCustomerDto } from '../models/GetByCustomerDto';
import type { GetByCustomerRo } from '../models/GetByCustomerRo';
import type { GetByPetDto } from '../models/GetByPetDto';
import type { GetByPetRo } from '../models/GetByPetRo';
import type { ReScheduleAppointmentDto } from '../models/ReScheduleAppointmentDto';
import type { ReScheduleAppointmentRo } from '../models/ReScheduleAppointmentRo';
import type { ScheduleAppointmentDto } from '../models/ScheduleAppointmentDto';
import type { ScheduleAppointmentRo } from '../models/ScheduleAppointmentRo';
import type { TelemedicineGenerateDoctorRoomLinkDto } from '../models/TelemedicineGenerateDoctorRoomLinkDto';
import type { TelemedicineGenerateDoctorRoomLinkRo } from '../models/TelemedicineGenerateDoctorRoomLinkRo';
import type { TriggerAppointmentEventDto } from '../models/TriggerAppointmentEventDto';
import type { UpdateAppointmentFromAdminDto } from '../models/UpdateAppointmentFromAdminDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AppointmentAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns CalendarRO
     * @throws ApiError
     */
    public appointmentAdminControllerCalendar({
        requestBody,
    }: {
        requestBody: CalenderDto,
    }): CancelablePromise<CalendarRO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/admin/calendar',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ScheduleAppointmentRo
     * @throws ApiError
     */
    public appointmentAdminControllerSchedule({
        requestBody,
    }: {
        requestBody: ScheduleAppointmentDto,
    }): CancelablePromise<ScheduleAppointmentRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/admin/schedule',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReScheduleAppointmentRo
     * @throws ApiError
     */
    public appointmentAdminControllerReschedule({
        requestBody,
    }: {
        requestBody: ReScheduleAppointmentDto,
    }): CancelablePromise<ReScheduleAppointmentRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/admin/reschedule',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TelemedicineGenerateDoctorRoomLinkRo
     * @throws ApiError
     */
    public appointmentAdminControllerGetTelemedicineRoom({
        requestBody,
    }: {
        requestBody: TelemedicineGenerateDoctorRoomLinkDto,
    }): CancelablePromise<TelemedicineGenerateDoctorRoomLinkRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/admin/telemedicine/generate-doctor-room-link',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AppointmentModel
     * @throws ApiError
     */
    public appointmentAdminControllerUpdateAppointment({
        requestBody,
    }: {
        requestBody: UpdateAppointmentFromAdminDto,
    }): CancelablePromise<AppointmentModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/appointment/admin/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AppointmentModel
     * @throws ApiError
     */
    public appointmentAdminControllerCancel({
        requestBody,
    }: {
        requestBody: CancelAppointmentDto,
    }): CancelablePromise<AppointmentModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/appointment/admin/cancel',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AppointmentModel
     * @throws ApiError
     */
    public appointmentAdminControllerGetAll({
        requestBody,
    }: {
        requestBody: GetAdminAppointmentListDto,
    }): CancelablePromise<Array<AppointmentModel>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/admin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetByCustomerRo
     * @throws ApiError
     */
    public appointmentAdminControllerGetCustomerRelated({
        requestBody,
    }: {
        requestBody: GetByCustomerDto,
    }): CancelablePromise<GetByCustomerRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/admin/get-customer-related',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetByPetRo
     * @throws ApiError
     */
    public appointmentAdminControllerGetPetRelated({
        requestBody,
    }: {
        requestBody: GetByPetDto,
    }): CancelablePromise<GetByPetRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/admin/get-pet-related',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetAppointmentExtendedRo
     * @throws ApiError
     */
    public appointmentAdminControllerGetOne({
        id,
    }: {
        id: string,
    }): CancelablePromise<GetAppointmentExtendedRo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/appointment/admin/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns AppointmentModel
     * @throws ApiError
     */
    public appointmentAdminControllerTriggerEvent({
        requestBody,
    }: {
        requestBody: TriggerAppointmentEventDto,
    }): CancelablePromise<AppointmentModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/admin/event',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
