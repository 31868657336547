/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddCommentDto } from '../models/AddCommentDto';
import type { AddInstructionsDto } from '../models/AddInstructionsDto';
import type { DeleteCommentDto } from '../models/DeleteCommentDto';
import type { GetAppointmentExtendedRo } from '../models/GetAppointmentExtendedRo';
import type { UpdateCommentDto } from '../models/UpdateCommentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AppointmentCommentAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns GetAppointmentExtendedRo
     * @throws ApiError
     */
    public appointmentResourcesControllerAddComment({
        requestBody,
    }: {
        requestBody: AddCommentDto,
    }): CancelablePromise<GetAppointmentExtendedRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/resources/admin/comment/add',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetAppointmentExtendedRo
     * @throws ApiError
     */
    public appointmentResourcesControllerUpdateComment({
        requestBody,
    }: {
        requestBody: UpdateCommentDto,
    }): CancelablePromise<GetAppointmentExtendedRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/resources/admin/comment/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetAppointmentExtendedRo
     * @throws ApiError
     */
    public appointmentResourcesControllerDeleteComment({
        requestBody,
    }: {
        requestBody: DeleteCommentDto,
    }): CancelablePromise<GetAppointmentExtendedRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/resources/admin/comment/delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetAppointmentExtendedRo
     * @throws ApiError
     */
    public appointmentResourcesControllerAddInstructions({
        requestBody,
    }: {
        requestBody: AddInstructionsDto,
    }): CancelablePromise<GetAppointmentExtendedRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/resources/admin/instruction/add',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
