/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateNoteDto } from '../models/CreateNoteDto';
import type { NoteModel } from '../models/NoteModel';
import type { UpdateNoteDto } from '../models/UpdateNoteDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NotesAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns NoteModel
     * @throws ApiError
     */
    public noteControllerCreate({
        requestBody,
    }: {
        requestBody: CreateNoteDto,
    }): CancelablePromise<NoteModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/note',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns NoteModel
     * @throws ApiError
     */
    public noteControllerFindAll({
        date,
        clinicId,
    }: {
        date: string,
        clinicId: string,
    }): CancelablePromise<Array<NoteModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/note',
            query: {
                'date': date,
                'clinicId': clinicId,
            },
        });
    }

    /**
     * @returns NoteModel
     * @throws ApiError
     */
    public noteControllerFindOne({
        id,
    }: {
        id: string,
    }): CancelablePromise<NoteModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/note/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns NoteModel
     * @throws ApiError
     */
    public noteControllerUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateNoteDto,
    }): CancelablePromise<NoteModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/note/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns number
     * @throws ApiError
     */
    public noteControllerRemove({
        id,
    }: {
        id: string,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/note/{id}',
            path: {
                'id': id,
            },
        });
    }

}
