import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import styles from './PinVerificationModal.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as LockIcon } from './assets/LockIcon.svg';
import {
  OtpInput,
  OtpInputRef,
} from 'pages/SetCredentials/components/OtpInput';
import { PIN_CODE_LENGTH } from 'utils/constants';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { logout } from 'utils';
import { UserPinSignInRo, apiClient } from 'service/api';
import jwtDecode from 'jwt-decode';
import { useModal } from 'hooks/useModal';

export type PinVerificationModalProps = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
};

export const PinVerificationModal: React.FC<PinVerificationModalProps> = ({
  setIsOpen,
}) => {
  const [pinCode, setPinCode] = useState<string>('');
  const [pinCodeVisible, setPinCodeVisible] = useState<boolean>(false);
  const [isWrong, setIsWrong] = useState<boolean>(false);
  const otpInputRef = useRef<OtpInputRef>(null);
  const { openModal, modal } = useModal();

  const { t } = useTranslation();

  const loginOnForgotPin = () => {
    logout();
  };

  const submitPin = async () => {
    try {
      const lastVerifiedUserId = jwtDecode<{ id: string }>(
        localStorage.getItem('token') as string,
      )?.id;
      const res = await apiClient.userAuth.userAuthControllerSignInByPin({
        requestBody: {
          pin: pinCode,
        },
      });

      if (lastVerifiedUserId !== res.user.id) {
        openModal({
          title: t('confirm_user_switch', {
            fullName: `${res?.user?.firstName || ''} ${
              res?.user?.lastName || ''
            }`,
          }),
          onConfirm: () => {
            authenticateUser(res);
            window.location.href = `/m/calendar`;
          },
          onDecline: () => {
            setPinCode('');
            otpInputRef?.current?.focusLatestInput();
          },
        });
      } else {
        authenticateUser(res);
        setIsOpen(false);
      }
    } catch (error) {
      setIsWrong(true);
    }
  };

  const authenticateUser = useCallback((res: UserPinSignInRo) => {
    localStorage.setItem('token', res.accessToken);
    localStorage.setItem('role', res.user.roles?.[0].role);
    localStorage.removeItem('isPinModalOpen');
  }, []);

  useEffect(() => {
    if (pinCode?.length === PIN_CODE_LENGTH) {
      submitPin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinCode]);

  return (
    <>
      {modal}
      <Modal
        open={true}
        footer={null}
        closable={false}
        className={styles.container}
        onCancel={() => {
          otpInputRef?.current?.focusLatestInput();
        }}
        centered>
        <div
          className={styles.contentContainer}
          onClick={() => otpInputRef?.current?.focusLatestInput()}>
          <div className={styles.topIconContainer}>
            <LockIcon />
          </div>
          <span className={styles.title}>
            <Trans
              i18nKey={'enter_pin_to_unlock'}
              components={{
                HIGHLIGHT: <span className={styles.hightlight} />,
                BR: <br />,
              }}
            />
          </span>
          <div className={styles.pinCodeContainer}>
            <OtpInput
              ref={otpInputRef}
              value={pinCode}
              setValue={setPinCode}
              length={PIN_CODE_LENGTH}
              type={pinCodeVisible ? 'text' : 'password'}
              errorKey={isWrong ? 'incorrect_pin_code' : undefined}
              autoFocus
            />
            <button
              type="button"
              className={styles.pinCodeVisibilityToggle}
              onClick={() => setPinCodeVisible((val) => !val)}>
              {pinCodeVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </button>
          </div>
          <span className={styles.loginIfForgotPin} onClick={loginOnForgotPin}>
            {t('login_if_forgot_pin')}
          </span>
        </div>
      </Modal>
    </>
  );
};
