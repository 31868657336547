/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CustomerCheckInRO = {
    type: CustomerCheckInRO.type | null;
};

export namespace CustomerCheckInRO {

    export enum type {
        PHONE_NUMBER = 'phone_number',
        EMAIL = 'email',
    }


}

