import React from 'react';
import { TaskModel } from 'service/api';
import { TaskPreview } from './components/TaskPreview';
import styles from './TaskList.module.scss';

export type TaskListProps = {
  tasks: TaskModel[];
  onTaskOpen: (task: TaskModel) => void;
  isCollapsed: boolean;
};

export const TaskList: React.FC<TaskListProps> = ({
  tasks,
  onTaskOpen,
  isCollapsed,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {tasks.map((task) => (
          <TaskPreview
            key={task.id}
            task={task}
            onOpen={onTaskOpen}
            isCollapsed={isCollapsed}
          />
        ))}
      </div>
    </div>
  );
};
