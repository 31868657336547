import React, { createContext, useContext, ReactNode } from 'react';

import { useNotification } from './useNotification';

export const AlertContext = createContext<ReturnType<
  typeof useNotification
> | null>(null);

type AlertContextProviderProps = {
  children: ReactNode;
};

export const AlertContextProvider = ({
  children,
}: AlertContextProviderProps) => {
  const alerts = useNotification();

  return (
    <AlertContext.Provider value={alerts}>{children}</AlertContext.Provider>
  );
};

export function useAlert() {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within a AlertContextProvider');
  }

  const [alert, alertHolder] = context.errorAlert;
  const [successAlert, successAlertHolder] = context.successAlert;

  return {
    alert,
    alertHolder,
    successAlert,
    successAlertHolder,
  };
}
