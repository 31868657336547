/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreatePetWithCustomerDto = {
    name: string;
    gender: CreatePetWithCustomerDto.gender;
    breed?: string;
    categoryId: string;
    isNeutered?: boolean;
};

export namespace CreatePetWithCustomerDto {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}

