/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RequestCompleteProfileDto = {
    appointmentId: string;
    deliveryMode: RequestCompleteProfileDto.deliveryMode;
};

export namespace RequestCompleteProfileDto {

    export enum deliveryMode {
        SMS = 'sms',
        EMAIL = 'email',
        PUSH = 'push',
    }


}

