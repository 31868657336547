/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateClinicDto = {
    name: string;
    email: string;
    phoneNumber: string;
    status: CreateClinicDto.status;
    longitude?: string;
    latitude?: string;
    address?: string;
    mapUrl?: string;
    tZone?: string;
    createdAt?: string;
};

export namespace CreateClinicDto {

    export enum status {
        INACTIVE = 'inactive',
        ACTIVE = 'active',
        PENDING = 'pending',
    }


}

