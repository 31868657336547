/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ClinicModel } from './ClinicModel';
import type { CustomerModel } from './CustomerModel';
import type { PetModel } from './PetModel';
import type { TaskChangeLogModel } from './TaskChangeLogModel';
import type { TaskCommentModel } from './TaskCommentModel';
import type { UserModel } from './UserModel';

export type TaskModel = {
    created_at: string;
    updated_at: string;
    title: string;
    description: string;
    date: string;
    status: TaskModel.status;
    clinicId: string;
    petId: string | null;
    customerId: string | null;
    assigneeId: string | null;
    appointmentId: string | null;
    clinic: ClinicModel;
    pet: PetModel | null;
    customer: CustomerModel | null;
    assignee: UserModel | null;
    changeLogs: Array<TaskChangeLogModel>;
    comments: Array<TaskCommentModel>;
    id: string;
};

export namespace TaskModel {

    export enum status {
        PENDING = 'pending',
        CANCELED = 'canceled',
        COMPLETED = 'completed',
    }


}

