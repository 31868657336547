/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerCheckInDto } from '../models/CustomerCheckInDto';
import type { CustomerCheckInRO } from '../models/CustomerCheckInRO';
import type { CustomerExtendedRo } from '../models/CustomerExtendedRo';
import type { CustomerForgotPasswordDto } from '../models/CustomerForgotPasswordDto';
import type { CustomerForgotPasswordRo } from '../models/CustomerForgotPasswordRo';
import type { CustomerSignInDto } from '../models/CustomerSignInDto';
import type { CustomerSignInRO } from '../models/CustomerSignInRO';
import type { CustomerSignUpDto } from '../models/CustomerSignUpDto';
import type { CustomerSignUpRO } from '../models/CustomerSignUpRO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomerAuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns CustomerExtendedRo
     * @throws ApiError
     */
    public customerAuthControllerMe(): CancelablePromise<CustomerExtendedRo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/auth/me',
        });
    }

    /**
     * @returns CustomerSignUpRO
     * @throws ApiError
     */
    public customerAuthControllerSignUp({
        requestBody,
    }: {
        requestBody: CustomerSignUpDto,
    }): CancelablePromise<CustomerSignUpRO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/auth/sign-up',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomerSignInRO
     * @throws ApiError
     */
    public customerAuthControllerSignIn({
        requestBody,
    }: {
        requestBody: CustomerSignInDto,
    }): CancelablePromise<CustomerSignInRO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/auth/sign-in',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomerForgotPasswordRo
     * @throws ApiError
     */
    public customerAuthControllerForgotPassword({
        requestBody,
    }: {
        requestBody: CustomerForgotPasswordDto,
    }): CancelablePromise<CustomerForgotPasswordRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/auth/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomerCheckInRO
     * @throws ApiError
     */
    public customerAuthControllerCheckIn({
        requestBody,
    }: {
        requestBody: CustomerCheckInDto,
    }): CancelablePromise<CustomerCheckInRO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/auth/check-in',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
