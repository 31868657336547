import { Tooltip } from 'components/Tooltip';
import React, { useMemo, useState } from 'react';
import { apiClient, TaskModel } from 'service/api';
import styles from './TaskPreview.module.scss';
import moment from 'moment-timezone';
import CheckBoxField from 'components/GeneralFields/CheckBox';
import { ReactComponent as PersonIcon } from '../../../../../../pages/Appointment/components/AppointmentView/assets/personIcon.svg';
import { ReactComponent as PawIcon } from '../../../../../../pages/Appointment/Calendar/components/Event/assets/paw.svg';
import useTasks from 'store/TaskContext';
import { ReactComponent as WarningIcon } from 'icons/warning/exclamation-triangle-fill.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useAuth from 'store/AuthContext';

export type TaskPreviewProps = {
  task: TaskModel;
  onOpen: (task: TaskModel) => void;
  isCollapsed: boolean;
};

export const TaskPreview: React.FC<TaskPreviewProps> = ({
  task,
  onOpen,
  isCollapsed,
}) => {
  const { authData } = useAuth();

  const { t } = useTranslation();
  const { sortAndSetTasks, tasks } = useTasks();
  const [isDone, setIsDone] = useState<boolean>(
    task.status === TaskModel.status.COMPLETED,
  );
  const [taskUpdateDisabled, setTaskUpdateDisabled] = useState<boolean>(false);

  const isVeryOverdue = useMemo(() => {
    const queryDate = new URLSearchParams(window.location.search).get('date');
    const selectedDate = queryDate ? moment(queryDate, 'YYYY-MM-DD') : moment();
    if (!moment().isSame(selectedDate, 'date')) {
      return false;
    }
    return !isDone && moment(task.date).isBefore(moment(), 'date');
  }, [isDone, task.date]);

  const taskTime = useMemo(() => {
    const date = moment(task.date);
    const hasNoAssignedTime =
      date.get('hours') === 4 && date.get('minutes') === 0;
    if (!isVeryOverdue) {
      return hasNoAssignedTime ? null : moment(task.date).format('HH:mm');
    }

    return date.isSame(moment().subtract(1, 'day'), 'date')
      ? t('yesterday')
      : date.format(hasNoAssignedTime || isCollapsed ? 'DD.MM' : 'DD.MM HH:mm');
  }, [isVeryOverdue, t, task.date, isCollapsed]);

  const onTaskStatusChange = async (isDone?: boolean) => {
    try {
      setTaskUpdateDisabled(true);
      const nextStatus = isDone
        ? TaskModel.status.COMPLETED
        : TaskModel.status.PENDING;
      if (nextStatus === task.status) {
        return;
      }
      const updatedTask = await apiClient.taskAdmin.taskAdminControllerUpdate({
        requestBody: {
          id: task.id,
          status: nextStatus,
        },
      });
      sortAndSetTasks(
        tasks.map((e) => {
          if (e.id === updatedTask.id) {
            return updatedTask;
          }
          return e;
        }),
      );
      setTaskUpdateDisabled(false);
    } catch (error) {
      setTaskUpdateDisabled(false);
    }
  };

  const hasWarning = useMemo(() => {
    return (
      !isDone &&
      ((taskTime && moment(task.date).isBefore(moment())) || isVeryOverdue)
    );
  }, [isDone, isVeryOverdue, task.date, taskTime]);

  return (
    <Tooltip
      className={styles.assigneeToolTip}
      trigger="hover"
      mouseEnterDelay={0.5}
      content={
        <>
          <div
            className={classNames(
              styles.assigneeToolTipContent,
              styles.descriptionToolTipContent,
            )}>
            <span className={styles.toolTipLabel}>{t('title')}:</span>{' '}
            {task.title}
            <br />
            {taskTime ? (
              <>
                <span className={classNames(styles.toolTipLabel)}>
                  {t('time')}:
                </span>{' '}
                <span
                  className={isVeryOverdue ? styles.isVeryOverdue : undefined}>
                  {taskTime}
                </span>
                <br />
              </>
            ) : null}
            {task?.assignee ? (
              <>
                <span className={styles.toolTipLabel}>{t('assignee')}:</span>{' '}
                {task.assignee?.firstName} {task.assignee?.lastName}
                <br />
              </>
            ) : null}
            {task?.customer ? (
              <>
                <span className={styles.toolTipLabel}>{t('customer')}:</span>{' '}
                {task.customer?.firstName} {task.customer?.lastName}
                <br />
              </>
            ) : null}
            {task?.pet ? (
              <>
                <span className={styles.toolTipLabel}>{t('pet')}:</span>{' '}
                {task.pet?.name}
                <br />
              </>
            ) : null}
            {task?.description ? (
              <>
                <span className={styles.toolTipLabel}>{t('description')}:</span>{' '}
                {task.description}
                <br />
              </>
            ) : null}
            {/* <br/> */}
          </div>
        </>
      }>
      <div
        className={classNames(
          styles.container,
          isCollapsed ? styles.smallPreview : null,
        )}
        onClick={() => onOpen(task)}>
        <div className={styles.topRow}>
          <span className={styles.title}>{task.title}</span>
          <span
            className={classNames(
              styles.time,
              isVeryOverdue ? styles.isVeryOverdue : null,
            )}>
            {taskTime || t('today')}
          </span>
        </div>
        <div
          className={classNames(
            styles.midRow,
            isCollapsed ? styles.midRowSmall : null,
          )}>
          {task.assignee ? (
            <>
              <div>
                <span
                  className={classNames(
                    styles.assignee,
                    task.assigneeId === authData?.id ? styles.assigneeMe : null,
                  )}>
                  {task.assignee?.firstName?.startsWith('Dr. ')
                    ? task.assignee?.firstName?.slice(4, 5)
                    : task.assignee?.firstName?.[0]}
                  {task.assignee?.lastName?.[0]}
                </span>
              </div>
            </>
          ) : (
            <div>
              <span className={classNames(styles.assignee, styles.assigneeAll)}>
                {t('all')}
              </span>
            </div>
          )}
          {hasWarning ? (
            <div className={styles.warningIconContainer}>
              <WarningIcon />
            </div>
          ) : null}
          {task.customerId ? (
            <div>
              <PersonIcon className={styles.personIcon} />
            </div>
          ) : null}
          {task.petId ? (
            <div>
              <PawIcon className={styles.pawIcon} />
            </div>
          ) : null}
        </div>
        <div className={styles.bottomRow}>
          <span className={styles.description}>{task.description}</span>
          {/* <div className={styles.checkboxContainer}>
          <svg version="1.1" viewBox="0 0 17.837 17.837">
            <g>
              <path
                fill="#fff"
                d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
              />
            </g>
          </svg>
        </div> */}
          <div
            className={styles.checkboxContainer}
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <CheckBoxField
              label={''}
              checked={isDone}
              disabled={taskUpdateDisabled}
              onChange={(e) => {
                setIsDone(e.target.checked);
                onTaskStatusChange(e.target.checked);
              }}
            />
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
