import { useNotification as useRcNotification } from 'rc-notification';
import type { CSSMotionProps } from 'rc-motion';

import styles from './Alert.module.scss';

const motion: CSSMotionProps = {
  motionName: 'alert-fade',
  motionAppear: true,
  motionEnter: true,
  motionLeave: true,
  onLeaveStart: (ele) => {
    const { offsetHeight } = ele;
    return { height: offsetHeight };
  },
  onLeaveActive: () => ({ height: 0, opacity: 0, margin: 0 }),
};

export const useNotification = () => {
  return {
    errorAlert: useRcNotification({
      motion,
      prefixCls: 'alert',
      className: () => styles.alert,
      closable: true,
    }),
    successAlert: useRcNotification({
      motion,
      prefixCls: 'alert-success',
      className: () => styles['alert-success'],
      closable: true,
    }),
  };
};
