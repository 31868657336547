import { useAlert } from 'components/Alert';
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Clinic } from 'types';

export type AppData = {
  clinic?: string;
  telemedicineClinicId?: string;
  clinics?: Clinic[];
};

type AppContextValue = {
  data: AppData;
  setAppData: (data: Partial<AppData>) => void;
};

const AppContext = createContext<AppContextValue | null>(null);

export const AppContextProvider = ({
  children,
  defaultData,
}: {
  children: ReactNode;
  defaultData: Partial<AppData>;
}) => {
  const [data, setAppData] = useState<AppData>({});
  const { alertHolder, successAlertHolder } = useAlert();

  const handleSetAppData = (data: Partial<AppData>) => {
    return setAppData((ps) => ({ ...ps, ...data }));
  };

  const value: AppContextValue = {
    data: { ...defaultData, ...data },
    setAppData: handleSetAppData,
  };

  return (
    <AppContext.Provider value={value}>
      {children}
      {alertHolder}
      {successAlertHolder}
    </AppContext.Provider>
  );
};

const useApp = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('AppContext must be used within a Provider');
  }

  return context;
};

export default useApp;
