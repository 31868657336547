import type {CancelablePromise} from '../../generated/core/CancelablePromise';
import type {BaseHttpRequest} from '../../generated/core/BaseHttpRequest';
import type {PetCategoriesModel} from '../models/PetsModel';

export class PetsService {
   constructor(public readonly httpRequest: BaseHttpRequest) {}
   public petsCategoriesGetAll({
      lang,
   }: {
      lang: string;
   }): CancelablePromise<PetCategoriesModel> {
      return this.httpRequest.request({
         method: 'GET',
         url: '/api/rest/pet_categories/categories',
         query: {
            lang,
         },
      });
   }

   public deletePet(petId: string): CancelablePromise<void> {
      return this.httpRequest.request({
         method: 'POST',
         url: '/api/rest/pets/delete',
         body: {
            where: {
               id: {_eq: petId},
            },
         },
      });
   }

   public createPet({
      petName,
      categoryId,
      customerId,
   }: {
      petName: string;
      categoryId: string;
      customerId: string;
   }): CancelablePromise<{data: {id: string}}> {
      return this.httpRequest.request({
         method: 'POST',
         url: '/api/rest/pets/create',
         body: {
            args: {
               name: petName,
               category_id: categoryId,
               pet_owners: {
                  data: {
                     owner_id: customerId,
                  },
               },
            },
         },
      });
   }
}
