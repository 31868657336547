/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClinicModel } from '../models/ClinicModel';
import type { CreateClinicDto } from '../models/CreateClinicDto';
import type { UpdateClinicDto } from '../models/UpdateClinicDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClinicAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ClinicModel
     * @throws ApiError
     */
    public clinicAdminControllerGetAll(): CancelablePromise<Array<ClinicModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/admin/clinic',
        });
    }

    /**
     * @returns ClinicModel
     * @throws ApiError
     */
    public clinicAdminControllerCreate({
        requestBody,
    }: {
        requestBody: CreateClinicDto,
    }): CancelablePromise<ClinicModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/clinic/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClinicModel
     * @throws ApiError
     */
    public clinicAdminControllerUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateClinicDto,
    }): CancelablePromise<ClinicModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/admin/clinic/update/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public clinicAdminControllerDelete({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/admin/clinic/delete/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ClinicModel
     * @throws ApiError
     */
    public clinicAdminControllerGetOne({
        id,
    }: {
        id: string,
    }): CancelablePromise<ClinicModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/admin/clinic/{id}',
            path: {
                'id': id,
            },
        });
    }

}
