import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout, Select } from 'antd';

import { logout } from 'utils';
import useAuth from 'store/AuthContext';
import useApp from '../../store/AppContext';
import { getClinicsOptions, getClinics } from 'service/clinic.api';

import { ReactComponent as Logo } from './assets/logo.svg';
import { ReactComponent as LogoSmall } from './assets/logoSmall.svg';
import { ReactComponent as UserIcon } from './assets/userIcon.svg';
import styles from './AppHeader.module.scss';
import { headerRoutes } from './HeaderRoutes';
import { Tooltip } from 'components/Tooltip';
import { MenuItem } from 'components/AppMenu/MenuData';
import { LanguageSwitcher } from './components/LanguageSwitcher';
import { joinPathnames } from 'components/AppMenu/utils/joinPathnames';
import { apiClient } from 'service/api';
import { ReactComponent as LockIcon } from 'hooks/useAuthenticationWindow/components/PinVerificationModal/assets/LockIcon.svg';

const { Option } = Select;
const { Header } = Layout;

interface AppHeaderProps {
  openMenu: () => void;
  openPinModal: () => void;
  menuCollapsed: boolean;
}

function AppHeader({ openMenu, openPinModal }: AppHeaderProps) {
  const { authData } = useAuth();
  const { t } = useTranslation();
  const { data: appData, setAppData } = useApp();

  const location = useLocation();

  const [clinicOptions, setClinicOptions] = useState<
    Awaited<ReturnType<typeof getClinicsOptions>>
  >([]);
  const isAuthorized = !!localStorage.getItem('token');

  useEffect(() => {
    (async () => {
      if (!isAuthorized) {
        return;
      }
      const clinics = await getClinics();
      const data = await getClinicsOptions(clinics);
      setAppData({ clinics });
      setClinicOptions(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRouteActive = useCallback(
    (pathname?: string) => {
      return pathname === location.pathname ? 'true' : undefined;
    },
    [location.pathname],
  );

  const isRouteChildActive = useCallback(
    (menuItems: MenuItem[] = []) => {
      return joinPathnames(menuItems).includes(location.pathname)
        ? 'true'
        : undefined;
    },
    [location.pathname],
  );

  if (!isAuthorized) {
    return (
      <Header className={styles.header}>
        <div className={styles.logoContainer}>
          <Logo className={styles.logo} />
          <LogoSmall className={styles.logoSmall} />
        </div>
        <div className={styles.rightContent}>
          <Tooltip
            className={styles.userPanelTooltip}
            content={
              <div className={styles.userPanelContent}>
                <LanguageSwitcher />
              </div>
            }>
            <div className={styles.userPanelWrapper}>
              <UserIcon />
              <button onClick={() => (window.location.href = '/login')}>
                {t('login')}
              </button>
            </div>
          </Tooltip>
        </div>
      </Header>
    );
  }

  return (
    <Header className={styles.header}>
      <div className={styles.logoContainer}>
        <Logo className={styles.logo} />
        <LogoSmall className={styles.logoSmall} />
      </div>
      <div className={styles.routes}>
        {headerRoutes
          .filter(
            (e) =>
              !e.requiresPermission ||
              e.requiresPermission === localStorage.role,
          )
          .map((headerRoute) =>
            headerRoute.children && !headerRoute.ignoreChildrenRender ? (
              <Tooltip
                className={styles.childRoutes}
                trigger={'hover'}
                content={
                  <div className={styles.childrenRoutesList}>
                    {headerRoute.children.map((childRoute) => (
                      <Link
                        className={styles.route}
                        to={childRoute.path as string}
                        is-active={isRouteActive(childRoute.path)}>
                        {t(childRoute.name as any)}
                      </Link>
                    ))}
                  </div>
                }>
                <Link
                  className={styles.route}
                  to={headerRoute.path as string}
                  is-active={isRouteChildActive(headerRoute.children)}>
                  {t(headerRoute.name as any)}
                </Link>
              </Tooltip>
            ) : (
              <Link
                className={styles.route}
                to={headerRoute.path as string}
                is-active={
                  isRouteActive(headerRoute.path) ||
                  isRouteChildActive(headerRoute.children)
                }
                {...(headerRoute.key === 'admin-button'
                  ? { onClick: openMenu }
                  : {})}>
                {t(headerRoute.name as any)}
              </Link>
            ),
          )}
      </div>
      <div className={styles.rightContent}>
        <Select
          placeholder={t('clinic')}
          value={appData.clinic}
          onChange={(value) => {
            apiClient.userAdmin.userAdminControllerUpdate({
              requestBody: {
                id: authData?.id as string,
                preferredClinicId: value,
              },
            });
            setAppData({ clinic: value });
          }}
          style={{
            width: 150,
          }}
          className={styles.clinicSelect}
          size={'small'}>
          {clinicOptions.map((opt: any) => (
            <Option key={opt.value} value={opt.value}>
              {opt.text}
            </Option>
          ))}
        </Select>

        <button
          className={styles.pinModalTriggerContainer}
          onClick={openPinModal}>
          <LockIcon />
          <span>{t('lock_or_switch_rexos')}</span>
        </button>

        <Tooltip
          className={styles.userPanelTooltip}
          content={
            <div className={styles.userPanelContent}>
              <LanguageSwitcher />
              <button className={styles.logOut} onClick={logout}>
                {t('logout')}
              </button>
            </div>
          }>
          <div className={styles.userPanelWrapper}>
            <UserIcon />
            <div className={styles.userPanel}>
              <span>{authData?.firstName}</span>
            </div>
          </div>
        </Tooltip>
      </div>
    </Header>
  );
}

export default AppHeader;
