import { useAlert } from 'components/Alert';
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { TaskModel } from 'service/api';
import moment from 'moment-timezone';

type TaskContextValue = {
  tasks: TaskModel[];
  setTasks: (tasks: TaskModel[]) => void;
  sortAndSetTasks: (tasks: TaskModel[]) => void;
  sortTasks: (tasks: TaskModel[]) => TaskModel[];
};

const TaskContext = createContext<TaskContextValue | null>(null);

export const TaskContextProvider = ({ children }: { children: ReactNode }) => {
  const [tasks, setTasks] = useState<TaskModel[]>([]);
  const { alertHolder, successAlertHolder } = useAlert();

  const sortTasks = (tasksToSort: TaskModel[]) => {
    const sortByDate = (a: TaskModel, b: TaskModel) =>
      moment(a.date).toDate().getTime() - moment(b.date).toDate().getTime();
    const tasksWithoutTime = tasksToSort.filter(
      (e) =>
        moment(e.date).get('hours') === 4 &&
        moment(e.date).get('minutes') === 0,
    );
    const tasksWithTime = tasksToSort.filter(
      (e) => !tasksWithoutTime.find((k) => k.id === e.id),
    );

    const queryDate = new URLSearchParams(window.location.search).get('date');
    const currentSelectedDate = queryDate
      ? moment(queryDate, 'YYYY-MM-DD')
      : moment();

    const overdueTasks = tasksToSort
      .filter((e) => {
        const taskDate = moment(e.date);
        return taskDate.isBefore(currentSelectedDate, 'date');
      })
      .sort(sortByDate);

    const futureTasks = tasksToSort.filter((e) => {
      if (overdueTasks.find((k) => k.id === e.id)) {
        return false;
      }
      const taskDate = moment(e.date);
      return !taskDate.isSame(currentSelectedDate, 'date');
    });

    const sortedTasksWithTime = tasksWithTime.sort(
      (a, b) =>
        moment(a.date).toDate().getTime() - moment(b.date).toDate().getTime(),
    );

    return [
      ...new Set(
        [
          ...overdueTasks,
          ...tasksWithoutTime,
          ...sortedTasksWithTime.filter(
            (e) => !overdueTasks.find((k) => k.id === e.id),
          ),
        ].filter((e) => !futureTasks.find((k) => k.id === e.id)),
      ),
    ];
  };

  const sortAndSetTasks = (tasksToSet: TaskModel[]) => {
    setTasks(sortTasks(tasksToSet));
  };

  const value: TaskContextValue = {
    tasks,
    setTasks,
    sortAndSetTasks,
    sortTasks,
  };

  return (
    <TaskContext.Provider value={value}>
      {children}
      {alertHolder}
      {successAlertHolder}
    </TaskContext.Provider>
  );
};

const useTasks = () => {
  const context = useContext(TaskContext);

  if (!context) {
    throw new Error('TaskContext must be used within a Provider');
  }

  return context;
};

export default useTasks;
