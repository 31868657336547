/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CustomerForgotPasswordRo = {
    done: boolean;
    commType: CustomerForgotPasswordRo.commType;
    fullEmailOrPhoneNumber: string;
};

export namespace CustomerForgotPasswordRo {

    export enum commType {
        SMS = 'sms',
        EMAIL = 'email',
        PUSH = 'push',
    }


}

