/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppointmentModel } from './AppointmentModel';
import type { UserModel } from './UserModel';

export type AppointmentStatusLogModel = {
    createdAt: string;
    fromStatus: AppointmentStatusLogModel.fromStatus;
    toStatus: AppointmentStatusLogModel.toStatus;
    event: any;
    appointmentId: string;
    info?: any;
    userId?: string;
    statusChangeReason?: AppointmentStatusLogModel.statusChangeReason;
    statusChangeComment?: string;
    appointment?: AppointmentModel;
    user?: UserModel;
    isTriggeredByRex: boolean;
    id: string;
};

export namespace AppointmentStatusLogModel {

    export enum fromStatus {
        PENDING = 'pending',
        WAITING_PAYMENT = 'waiting_payment',
        EXPIRED = 'expired',
        APPROVED = 'approved',
        IN_WAITING_ROOM = 'in_waiting_room',
        IN_TREATMENT = 'in_treatment',
        CHECKING_OUT = 'checking_out',
        MISSED = 'missed',
        CANCELED = 'canceled',
        COMPLETED = 'completed',
    }

    export enum toStatus {
        PENDING = 'pending',
        WAITING_PAYMENT = 'waiting_payment',
        EXPIRED = 'expired',
        APPROVED = 'approved',
        IN_WAITING_ROOM = 'in_waiting_room',
        IN_TREATMENT = 'in_treatment',
        CHECKING_OUT = 'checking_out',
        MISSED = 'missed',
        CANCELED = 'canceled',
        COMPLETED = 'completed',
    }

    export enum statusChangeReason {
        CUSTOMER_CHOSE_OTHER_CLINIC = 'customer_chose_other_clinic',
        CUSTOMER_PET_GOT_BETTER = 'customer_pet_got_better',
        CUSTOMER_OTHER = 'customer_other',
        CUSTOMER_CANNOT_MAKE_IT_AT_THIS_TIME = 'customer_cannot_make_it_at_this_time',
        TEAM_MEMBER_REX_CANCELED = 'team_member_rex_canceled',
        TEAM_MEMBER_CUSTOMER_CANCELED = 'team_member_customer_canceled',
    }


}

