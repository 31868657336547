import React, { useCallback, useMemo, useState } from 'react';
import { Collapse } from 'antd';
import { TFuncKey, useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from 'icons/warning/exclamation-triangle-fill.svg';
import { apiClient, TaskModel } from 'service/api';

import styles from './TaskStatusTransition.module.scss';
import moment from 'moment-timezone';

interface TaskStatusTransitionProps {
  task: TaskModel;
  onSave: (task: TaskModel) => void;
}

export const TaskStatusTransition = ({
  task,
  onSave,
}: TaskStatusTransitionProps) => {
  const [taskData, setTaskData] = useState<TaskModel>(task);
  const [isProcessing, setIsProcessing] = useState(false);
  const taskTime = useMemo(() => {
    const date = moment(task.date);
    return date.get('hours') === 4 && date.get('minutes') === 0
      ? null
      : moment(task.date).format('HH:mm');
  }, [task.date]);

  const hasWarning = useMemo(() => {
    return (
      taskData.status === TaskModel.status.PENDING &&
      taskTime &&
      moment(taskData.date).isBefore(moment())
    );
  }, [taskData, taskTime]);
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (newStatus: TaskModel.status) => {
      setIsProcessing(true);

      const updatedTask = await apiClient.taskAdmin.taskAdminControllerUpdate({
        requestBody: {
          id: taskData.id,
          status: newStatus,
        },
      });

      setIsProcessing(false);

      if (updatedTask) {
        setTaskData(updatedTask);

        onSave(updatedTask);
      }
    },
    [taskData, onSave],
  );

  const nextPossibleStatuses = useMemo(() => {
    const statuses = [
      { label: t('task_cancel_status'), value: TaskModel.status.CANCELED },
      { label: t('task_pending_status'), value: TaskModel.status.PENDING },
      { label: t('task_completed_status'), value: TaskModel.status.COMPLETED },
    ];
    return statuses.filter((e) => e.value !== taskData.status);
  }, [t, taskData.status]);

  return (
    <>
      <Collapse
        expandIconPosition="end"
        activeKey={'statuses'}
        onChange={() => {}}>
        <Collapse.Panel
          collapsible={undefined}
          header={
            <div className="w-32 md:w-40 mr-2 flex items-center">
              {hasWarning && (
                <WarningIcon
                  style={{ width: 16 }}
                  className="mr-3 z-0 pointer-events-none"
                />
              )}

              {t(taskData.status as TFuncKey)}
            </div>
          }
          key="statuses">
          <div>
            <div>
              <div className="flex flex-wrap gap-2">
                {nextPossibleStatuses.map((entry) => (
                  <button
                    type="button"
                    className={styles.button}
                    disabled={isProcessing}
                    onClick={() => handleSubmit(entry.value)}
                    key={entry.value}>
                    {entry.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};
