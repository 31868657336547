/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetMedicalEventsRo } from '../models/GetMedicalEventsRo';
import type { MarkAsDoneDto } from '../models/MarkAsDoneDto';
import type { MedicalEventModel } from '../models/MedicalEventModel';
import type { RemoveDto } from '../models/RemoveDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MedicalEventService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns GetMedicalEventsRo
     * @throws ApiError
     */
    public medicalEventControllerGetAll({
        petId,
    }: {
        petId?: string,
    }): CancelablePromise<GetMedicalEventsRo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/medical-event/all',
            query: {
                'petId': petId,
            },
        });
    }

    /**
     * @returns MedicalEventModel
     * @throws ApiError
     */
    public medicalEventControllerGetOne({
        medicalEventId,
    }: {
        medicalEventId: string,
    }): CancelablePromise<MedicalEventModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/medical-event/{medicalEventId}',
            path: {
                'medicalEventId': medicalEventId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public medicalEventControllerMarkAsDone({
        requestBody,
    }: {
        requestBody: MarkAsDoneDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/medical-event/mark-as-done',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public medicalEventControllerRemove({
        requestBody,
    }: {
        requestBody: RemoveDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/medical-event/remove',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
