/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSkillDto } from '../models/CreateSkillDto';
import type { TreatmentModel } from '../models/TreatmentModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TreatmentAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns TreatmentModel
     * @throws ApiError
     */
    public treatmentAdminControllerCreateSkill({
        requestBody,
    }: {
        requestBody: CreateSkillDto,
    }): CancelablePromise<TreatmentModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/treatment/skill',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TreatmentModel
     * @throws ApiError
     */
    public treatmentAdminControllerGetSkills(): CancelablePromise<Array<TreatmentModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/admin/treatment/skill',
        });
    }

    /**
     * @returns TreatmentModel
     * @throws ApiError
     */
    public treatmentAdminControllerUpdateSkill({
        requestBody,
    }: {
        requestBody: CreateSkillDto,
    }): CancelablePromise<TreatmentModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/treatment/skill/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TreatmentModel
     * @throws ApiError
     */
    public treatmentAdminControllerGetTreatments({
        doctorId,
    }: {
        doctorId?: string,
    }): CancelablePromise<Array<TreatmentModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/admin/treatment',
            query: {
                'doctorId': doctorId,
            },
        });
    }

    /**
     * @returns TreatmentModel
     * @throws ApiError
     */
    public treatmentAdminControllerGetById({
        id,
    }: {
        id: string,
    }): CancelablePromise<TreatmentModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/admin/treatment/{id}',
            path: {
                'id': id,
            },
        });
    }

}
