import React, { useCallback, useState } from 'react';
import { AppointmentCommentModel, TaskCommentModel } from 'service/api';
import styles from './Comment.module.scss';
import { ReactComponent as ClockIcon } from './assets/ClockIcon.svg';
import moment from 'moment-timezone';
import useAuth from 'store/AuthContext';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import classNames from 'classnames';
import { useModal } from 'hooks/useModal';
import { useTranslation } from 'react-i18next';

export type CommentProps = {
  comment: AppointmentCommentModel | TaskCommentModel;
  onDelete: (id: string) => Promise<void>;
  onEditStart: (comment: AppointmentCommentModel | TaskCommentModel) => void;
  isCurrentlyEditing: boolean;
};

export const Comment: React.FC<CommentProps> = ({
  comment,
  onDelete,
  onEditStart,
  isCurrentlyEditing,
}) => {
  const { authData } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { openModal, modal } = useModal();
  const { t } = useTranslation();

  const handleDelete = useCallback(async () => {
    setIsLoading(true);
    try {
      openModal({
        title: t('delete_comment_warning_title'),
        onDecline: () => {
          setIsLoading(false);
        },
        onConfirm: async () => {
          await onDelete(comment.id);
          setIsLoading(false);
        },
      });
    } catch (error) {
      setIsLoading(false);
    }
  }, [comment.id, onDelete, openModal, t]);

  return (
    <div className={styles.container}>
      {modal}
      <div
        className={classNames(
          styles.innerContainer,
          isCurrentlyEditing ? styles.currentlyEditing : null,
        )}>
        <div className={styles.topRow}>
          <span className={styles.owner}>{`${comment.user?.firstName || ''} ${
            comment.user?.lastName || ''
          }`}</span>
          <ClockIcon />
          <span className={styles.date}>
            {moment(comment.createdAt).format('DD.MM.YYYY, HH:mm')}
          </span>

          {authData?.id === comment.userId && (
            <>
              <button
                className={styles.editButton}
                type="button"
                onClick={() => {
                  onEditStart(comment);
                }}>
                <EditFilled />
              </button>
              <button
                className={styles.deleteButton}
                type="button"
                onClick={handleDelete}
                disabled={isLoading}>
                <DeleteFilled />
              </button>
            </>
          )}
        </div>
        <span className={styles.comment}>{comment.text}</span>
      </div>
    </div>
  );
};
