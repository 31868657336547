import {PetsService} from './services/PetsService';
import {ApiClient, OpenAPIConfig} from '../generated';

export class ApiClientExtended extends ApiClient {
   public readonly pets: PetsService;

   constructor(config?: Partial<OpenAPIConfig>) {
      super(config);

      this.pets = new PetsService(this.request);
   }
}
