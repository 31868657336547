import axios from 'axios';
import { logout } from './index';
import i18n from '../i18n';

export const uploadUrl = `${process.env.REACT_APP_UPLOAD}/upload`;
export const baseUrl = `${
  process.env.REACT_APP_API_OLD || process.env.REACT_APP_API
}/api/rest`;
export const baseUrlV2 = `${process.env.REACT_APP_API}/api/v2`;

const Api = axios.create({
  baseURL: '/',
  timeout: 20 * 1000,
});

Api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          logout();
          break;
        default: {
          let msg = error.response.data.error;

          try {
            const errorObj = JSON.parse(msg);

            if (errorObj?.error_slug) {
              msg = i18n.t(errorObj?.error_slug);
            } else {
              msg = errorObj?.message;
            }
          } catch (e) {}
        }
      }
    } else if (error.code === 'ECONNABORTED') {
      console.error('connection timeout');
    }
    return Promise.reject(error);
  },
);

export default Api;
