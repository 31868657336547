/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDoctorDto } from '../models/CreateDoctorDto';
import type { DoctorModel } from '../models/DoctorModel';
import type { GetDoctorsDto } from '../models/GetDoctorsDto';
import type { UpdateDoctorDto } from '../models/UpdateDoctorDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DoctorAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns DoctorModel
     * @throws ApiError
     */
    public doctorAdminControllerGetAll({
        requestBody,
    }: {
        requestBody: GetDoctorsDto,
    }): CancelablePromise<Array<DoctorModel>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/doctor',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DoctorModel
     * @throws ApiError
     */
    public doctorAdminControllerGetById({
        id,
    }: {
        id: string,
    }): CancelablePromise<DoctorModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/admin/doctor/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns DoctorModel
     * @throws ApiError
     */
    public doctorAdminControllerCreate({
        requestBody,
    }: {
        requestBody: CreateDoctorDto,
    }): CancelablePromise<DoctorModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/doctor/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DoctorModel
     * @throws ApiError
     */
    public doctorAdminControllerUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateDoctorDto,
    }): CancelablePromise<DoctorModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/admin/doctor/update/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public doctorAdminControllerDelete({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/admin/doctor/delete/{id}',
            path: {
                'id': id,
            },
        });
    }

}
