/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LegalNoticeModel } from '../models/LegalNoticeModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LegalNoticeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns LegalNoticeModel
     * @throws ApiError
     */
    public legalNoticeUnauthControllerGetLatest(): CancelablePromise<Array<LegalNoticeModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/legal-notice/latest',
        });
    }

    /**
     * @returns LegalNoticeModel
     * @throws ApiError
     */
    public legalNoticeUnauthControllerGetAccountCreationLegalNotices(): CancelablePromise<Array<LegalNoticeModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/legal-notice/account-creation',
        });
    }

    /**
     * @returns LegalNoticeModel
     * @throws ApiError
     */
    public legalNoticeUnauthControllerGetCardOnFileLegalNotices(): CancelablePromise<Array<LegalNoticeModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/legal-notice/card-on-file',
        });
    }

}
