/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetPaymentStatusRo = {
    status: GetPaymentStatusRo.status | null;
};

export namespace GetPaymentStatusRo {

    export enum status {
        PENDING = 'pending',
        APPROVED = 'approved',
        FAILED = 'failed',
        REQUIRES_ACTION = 'requires_action',
        REFUNDED = 'refunded',
    }


}

