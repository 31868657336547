import axios from 'axios';

import { OpenAPIConfig } from './generated';
import { ApiClientExtended } from './extended';

import { responseErrorHandler } from './responseErrorHandler';

const apiClientConfig: Partial<OpenAPIConfig> = {
  BASE: process.env.REACT_APP_API,
};

export const getToken = () => {
  return localStorage.getItem('token') || '';
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    responseErrorHandler(error);
    return Promise.reject(error);
  },
);

axios.interceptors.request.use((config) => {
  const newApiUrl = process.env.REACT_APP_API;
  const oldApiUrl = process.env.REACT_APP_API_OLD;

  if (config.url?.includes('api/rest/') && oldApiUrl) {
    config.url = config.url?.replace(newApiUrl!, oldApiUrl);
  }

  return config;
});

export const apiClient = new ApiClientExtended({
  ...apiClientConfig,
  TOKEN: async () => {
    return getToken();
  },
});
