/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateIntentDto } from '../models/CreateIntentDto';
import type { CreateIntentRo } from '../models/CreateIntentRo';
import type { GetPaymentStatusRo } from '../models/GetPaymentStatusRo';
import type { PaymentTransactionModel } from '../models/PaymentTransactionModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentTransactionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns GetPaymentStatusRo
     * @throws ApiError
     */
    public paymentTransactionControllerFindPaymentStatusByAppointmentId({
        appointmentId,
    }: {
        appointmentId: string,
    }): CancelablePromise<GetPaymentStatusRo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/payments/status',
            query: {
                'appointmentId': appointmentId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public paymentTransactionControllerResetPaymentStatus({
        appointmentId,
    }: {
        appointmentId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/payments/status/reset',
            query: {
                'appointmentId': appointmentId,
            },
        });
    }

    /**
     * @returns PaymentTransactionModel
     * @throws ApiError
     */
    public paymentTransactionControllerCreate(): CancelablePromise<PaymentTransactionModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/payments',
        });
    }

    /**
     * @returns PaymentTransactionModel
     * @throws ApiError
     */
    public paymentTransactionControllerFindAll(): CancelablePromise<Array<PaymentTransactionModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/payments',
        });
    }

    /**
     * @returns PaymentTransactionModel
     * @throws ApiError
     */
    public paymentTransactionControllerFindOne({
        id,
    }: {
        id: string,
    }): CancelablePromise<PaymentTransactionModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/payments/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaymentTransactionModel
     * @throws ApiError
     */
    public paymentTransactionControllerUpdate({
        id,
    }: {
        id: string,
    }): CancelablePromise<PaymentTransactionModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/payments/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaymentTransactionModel
     * @throws ApiError
     */
    public paymentTransactionControllerRemove({
        id,
    }: {
        id: string,
    }): CancelablePromise<Array<PaymentTransactionModel>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/payments/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CreateIntentRo
     * @throws ApiError
     */
    public paymentTransactionControllerCreateIntent({
        requestBody,
    }: {
        requestBody: CreateIntentDto,
    }): CancelablePromise<CreateIntentRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/payments/create-intent',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public paymentTransactionControllerWebhookFromStripe(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/payments/stripe/webhook',
        });
    }

}
