/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MedicalEventRuleModel } from './MedicalEventRuleModel';
import type { PetModel } from './PetModel';

export type MedicalEventModel = {
    createdAt: string;
    updatedAt: string;
    petId: string;
    date: string | null;
    medicalEventRuleId: string;
    status: MedicalEventModel.status;
    appointmentId: string | null;
    pet: PetModel;
    medicalEventRule: MedicalEventRuleModel;
    availableStartingAt: string;
    id: string;
};

export namespace MedicalEventModel {

    export enum status {
        PENDING = 'pending',
        DONE_BY_CUSTOMER = 'done_by_customer',
        DONE_BY_IBH = 'done_by_ibh',
        DELETED = 'deleted',
        DELETED_BY_CUSTOMER = 'deleted_by_customer',
    }


}

