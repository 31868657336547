/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TaskModel } from './TaskModel';
import type { UserModel } from './UserModel';

export type TaskChangeLogModel = {
    createdAt: string;
    updatedAt: string;
    property: TaskChangeLogModel.property;
    oldValue: string | null;
    newValue: string | null;
    taskId: string;
    userId: string;
    appointment: TaskModel | null;
    user: UserModel | null;
    isTriggeredByRex: boolean;
    id: string;
};

export namespace TaskChangeLogModel {

    export enum property {
        CLINIC = 'clinic',
        PET = 'pet',
        CUSTOMER = 'customer',
        ASSIGNEE = 'assignee',
        STATUS = 'status',
        DATE = 'date',
        DESCRIPTION = 'description',
        TITLE = 'title',
    }


}

