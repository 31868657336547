/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetNewerVersionsDto } from '../models/GetNewerVersionsDto';
import type { GetNewerVersionsRo } from '../models/GetNewerVersionsRo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AppVersionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns GetNewerVersionsRo
     * @throws ApiError
     */
    public appVerionControllerGetNewerVersions({
        requestBody,
    }: {
        requestBody: GetNewerVersionsDto,
    }): CancelablePromise<GetNewerVersionsRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/app-version/get-newer-versions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
