import React from 'react';
import { Redirect, Route, Switch, RouteProps } from 'react-router-dom';

import { useAppointmentWarnings } from 'hooks/useAppointmentWarnings';

import menuData, { MenuItem } from './components/AppMenu/MenuData';
import { headerRoutes } from 'components/AppHeader/HeaderRoutes';
import { SetCredentials } from 'pages/SetCredentials';
import { Login } from 'pages/Login';
import { ForgotPassword } from 'pages/ForgotPassword';

export const unauthorizedRoutes = [
  '/auth/reset-password',
  '/login',
  '/forgot-password',
];

const Routes = () => {
  useAppointmentWarnings();

  const renderMenuRoute = (data: MenuItem) => {
    let path = data.path;
    let component = data.component;

    if (data.requiresPermission) {
      return (
        <PrivateRoute
          requiresPermission={data.requiresPermission}
          exact={data.exact}
          key={data.key}
          path={path}
          component={component}
        />
      );
    } else {
      return (
        <Route
          exact={data.exact}
          key={data.key}
          path={path}
          render={(props) => {
            const Component = component as any;
            return Component ? <Component {...props} /> : null;
          }}
        />
      );
    }
  };

  const renderMenuRoutes = (data: MenuItem[]): any => {
    return data.map((c) => {
      if (c.children) {
        return renderMenuRoutes(c.children);
      } else {
        return renderMenuRoute(c);
      }
    });
  };

  return (
    <Switch>
      <Route exact path="/auth/reset-password" component={SetCredentials} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/">
        <Redirect to="/m/calendar" />
      </Route>
      {renderMenuRoutes([...menuData, ...headerRoutes])}
    </Switch>
  );
};

export default Routes;

interface PrivateRouteProps extends RouteProps {
  requiresPermission: string;
}

const PrivateRoute = ({
  component,
  requiresPermission,
  ...rest
}: PrivateRouteProps) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.role !== requiresPermission) {
          console.error('You have not permission');
          // logout()
        }

        const Component = component as any;

        return <Component {...props} />;
      }}
    />
  );
};
