import React from 'react';
import { CheckboxProps, Form, Checkbox } from 'antd';
import { useTranslation, TFuncKey } from 'react-i18next';

interface CheckBoxFieldProps extends CheckboxProps {
  label: string;
  wrapperClassName?: string;
  errorMessage?: string;
  hidden?: boolean;
  required?: boolean;
}

const CheckBoxField = ({
  errorMessage,
  label,
  wrapperClassName,
  hidden,
  required,
  ...rest
}: CheckBoxFieldProps) => {
  const { t } = useTranslation();

  if (hidden) return null;

  const formattedLabel = label ? (
    <div
      className={`${
        required && 'antd-field-required'
      } w-32 md:w-40 mr-2 flex justify-end items-center`}>
      {label}
    </div>
  ) : undefined;

  return (
    <Form.Item
      help={errorMessage && t(errorMessage as TFuncKey)}
      validateStatus={typeof errorMessage === 'string' ? 'error' : 'success'}
      className={wrapperClassName}
      label={formattedLabel}>
      <div
        style={{ flex: 1, overflow: 'hidden', display: 'flex' }}
        className={'rex-field rex-checkbox'}>
        <div className={'flex-1 flex'}>
          <Checkbox {...rest} />
        </div>
      </div>
    </Form.Item>
  );
};

export default CheckBoxField;
