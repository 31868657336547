import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import commonEN from './locales/en/common.json';
import commonDE from './locales/de/common.json';
import columnsEN from './locales/en/columns.json';
import columnsDE from './locales/de/columns.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const resources = {
  en: {
    common: commonEN,
    columns: columnsEN,
  },
  de: {
    common: commonDE,
    columns: columnsDE,
  },
} as const;

export const defaultNS = 'common';

export type ResourceKey = keyof typeof resources;

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ['common', 'columns'],
    defaultNS,
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
