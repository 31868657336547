/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MedicamentModel } from './MedicamentModel';
import type { VisitModel } from './VisitModel';

export type VisitItemModel = {
    createdAt: string;
    updatedAt: string;
    type: VisitItemModel.type;
    name: string;
    code: string;
    areaCode: string;
    areaName: string;
    quantity: number;
    unit: string;
    factor: number;
    pricePerUnit: number;
    price: number;
    vatPercent: number;
    status: VisitItemModel.status;
    visitId: string;
    medicamentId: string;
    medicament: MedicamentModel;
    visit: VisitModel;
    id: string;
};

export namespace VisitItemModel {

    export enum type {
        SERVICE = 'service',
        PRODUCT = 'product',
        MEDICATION = 'medication',
        FOOD = 'food',
    }

    export enum status {
        APPLIED = 'applied',
        DISPENSED = 'dispensed',
    }


}

