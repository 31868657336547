/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddTaskCommentDto } from '../models/AddTaskCommentDto';
import type { DeleteTaskCommentDto } from '../models/DeleteTaskCommentDto';
import type { TaskDto } from '../models/TaskDto';
import type { TaskModel } from '../models/TaskModel';
import type { TaskUpdateDto } from '../models/TaskUpdateDto';
import type { UpdateTaskCommentDto } from '../models/UpdateTaskCommentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TaskAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns TaskModel
     * @throws ApiError
     */
    public taskAdminControllerCreate({
        requestBody,
    }: {
        requestBody: TaskDto,
    }): CancelablePromise<TaskModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/task/admin/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TaskModel
     * @throws ApiError
     */
    public taskAdminControllerUpdate({
        requestBody,
    }: {
        requestBody: TaskUpdateDto,
    }): CancelablePromise<TaskModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/task/admin/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TaskModel
     * @throws ApiError
     */
    public taskAdminControllerGetOne({
        id,
    }: {
        id: string,
    }): CancelablePromise<TaskModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/task/admin/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TaskModel
     * @throws ApiError
     */
    public taskAdminControllerAddComment({
        requestBody,
    }: {
        requestBody: AddTaskCommentDto,
    }): CancelablePromise<TaskModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/task/admin/comment/add',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TaskModel
     * @throws ApiError
     */
    public taskAdminControllerUpdateComment({
        requestBody,
    }: {
        requestBody: UpdateTaskCommentDto,
    }): CancelablePromise<TaskModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/task/admin/comment/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TaskModel
     * @throws ApiError
     */
    public taskAdminControllerDeleteComment({
        requestBody,
    }: {
        requestBody: DeleteTaskCommentDto,
    }): CancelablePromise<TaskModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/task/admin/comment/delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
