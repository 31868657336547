import { t } from 'i18next';
import moment from 'moment';
import { TaskChangeLogModel } from 'service/api';
import { AppointmentChangeLog } from 'service/api/generated/models/AppointmentChangeLog';

export const getFormatedProperty = (
  value: string | undefined | null,
  property: AppointmentChangeLog.property | TaskChangeLogModel.property,
  currentLanguage: string,
) => {
  if (!value) {
    return 'None';
  } else if (property === AppointmentChangeLog.property.DATE) {
    return moment(value).format('HH:mm, DD.MM.YYYY');
  } else if (property === AppointmentChangeLog.property.STATUS) {
    return t(value);
  } else if (property === AppointmentChangeLog.property.TREATMENT) {
    return JSON.parse(value)[currentLanguage];
  } else if (property === AppointmentChangeLog.property.INSTRUCTIONS) {
    return JSON.parse(value)
      .map((i: { [lang: string]: string }) => i[currentLanguage])
      .join(', ');
  }
  return value;
};
