import React, { useEffect, useMemo } from 'react';
import styles from './PasswordStrengthValidator.module.scss';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export type PasswordStrengthValidatorProps = {
  password: string;
  minLength: number;
  setIsValid: (isValid: boolean) => void;
};

export const PasswordStrengthValidator: React.FC<
  PasswordStrengthValidatorProps
> = ({ password, minLength, setIsValid }) => {
  const { t } = useTranslation();

  const criterias = useMemo(() => {
    return [
      {
        title: t('must_be_at_least_n_characters', {
          length: minLength,
        }),
        isValid: password.length >= minLength,
      },
      {
        title: t('must_contain_at_least_one_capital_letter'),
        isValid: new RegExp(/[A-Z]/).test(password),
      },
      {
        title: t('must_contain_at_least_one_special_character'),
        // eslint-disable-next-line no-useless-escape
        isValid: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password),
      },
    ];
  }, [minLength, password, t]);

  useEffect(() => {
    setIsValid(!criterias.some((e) => !e.isValid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criterias]);

  return (
    <div className={styles.container}>
      {criterias.map((criteria, i) => (
        <div
          key={i}
          className={styles.criteria}
          is-valid={criteria.isValid ? 'true' : 'false'}>
          <div className={styles.iconContainer}>
            {criteria.isValid ? (
              <CheckCircleOutlined />
            ) : (
              <CloseCircleOutlined />
            )}
          </div>
          {criteria.title}
        </div>
      ))}
    </div>
  );
};
