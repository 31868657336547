import { ConfigProvider, Layout, Row, Spin } from 'antd';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ErrorBoundary } from '@sentry/react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import 'moment/locale/de';
import 'moment/locale/en-gb';

import enUS from 'antd/lib/locale/en_US';
import deDE from 'antd/lib/locale/de_DE';

import AppMenu from './components/AppMenu/AppMenu';
import menuData from './components/AppMenu/MenuData';
import AppHeader from './components/AppHeader/AppHeader';

import { AlertContextProvider } from 'components/Alert';

import { ErrorScreen } from 'pages/Error';

import { initSentry } from 'utils/sentryLogger';

import { AppContextProvider, AppData } from './store/AppContext';
import { AuthContextProvider } from './store/AuthContext';

import Routes, { unauthorizedRoutes } from './Routes';

import { ResourceKey } from 'i18n';

import 'antd/dist/antd.min.css';
import { UserModel, apiClient } from 'service/api';
import { useAuthenticationWindow } from 'hooks/useAuthenticationWindow/useAuthenticationWindow';
import { VERIFICATION_PIN_TIMEOUT_IN_SECONDS } from 'utils/constants';
import AppWorkflow from 'components/AppWorkflow/AppWorkflow';
import { TaskContextProvider } from 'store/TaskContext';

const { Content } = Layout;

const localeConfig = {
  de: deDE,
  en: enUS,
};

export const customLocaleSpecs = {
  de: {
    weekdaysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthsShort: [
      'Jän',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez',
    ],
  },
  en: {
    weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthsShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },
};

initSentry();

function App() {
  const token = useRef(
    new URLSearchParams(window.location.search).get('token'),
  );
  const tokenCheckLogicRan = useRef(false);
  if (!tokenCheckLogicRan.current) {
    tokenCheckLogicRan.current = true;
    if (token.current && localStorage.getItem('token')) {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
    }
  }
  const isAuthorized = !!localStorage.getItem('token');

  const { PinVerificationModal, setIsPinModalOpen } = useAuthenticationWindow(
    VERIFICATION_PIN_TIMEOUT_IN_SECONDS,
  );

  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);
  const [initialAppData, setInitialAppData] = useState<Partial<AppData>>();

  const { i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  const history = useHistory();

  const closeMenu = useCallback(() => {
    setMenuCollapsed(true);
  }, [setMenuCollapsed]);

  const openMenu = useCallback(() => {
    setMenuCollapsed(false);
  }, [setMenuCollapsed]);

  useEffect(() => {
    moment.updateLocale(currentLanguage, {
      week: {
        dow: 1,
      },
      ...customLocaleSpecs[currentLanguage as ResourceKey],
    });
    momentTz.updateLocale(currentLanguage, {
      week: {
        dow: 1,
      },
      ...customLocaleSpecs[currentLanguage as ResourceKey],
    });
  }, [currentLanguage]);

  const fetchInitialAppData = async (userData: UserModel) => {
    if (!isAuthorized) {
      return;
    }
    const clinics = await apiClient.clinicAdmin.clinicAdminControllerGetAll();
    const nonTelemedicineClinics = clinics.filter(
      (clinic) => !clinic.isTelemedicine,
    );
    const clinicId =
      nonTelemedicineClinics.find((e) => e.id === (userData as any)?.clinicId)
        ?.id || nonTelemedicineClinics[0].id;
    const telemedicineClinicId = clinics.find(
      (clinic) => clinic.isTelemedicine,
    )?.id;

    setInitialAppData({
      clinic: userData.preferredClinicId || clinicId,
      telemedicineClinicId,
    });
  };

  if (unauthorizedRoutes.includes(history.location.pathname)) {
    return (
      <AlertContextProvider>
        <ErrorBoundary fallback={ErrorScreen}>
          <ConfigProvider locale={localeConfig[currentLanguage as ResourceKey]}>
            <AuthContextProvider afterAuth={fetchInitialAppData}>
              <AppContextProvider defaultData={initialAppData || {}}>
                <Layout style={{ height: '100%', paddingTop: 64 }}>
                  <Layout>
                    <>
                      <AppHeader
                        openMenu={openMenu}
                        menuCollapsed={menuCollapsed}
                        openPinModal={() => setIsPinModalOpen(true)}
                      />
                      <Content className={'app-content'}>
                        <div className={'app-content-child'}>
                          <Suspense
                            fallback={
                              <Row
                                className="h-full"
                                justify="center"
                                align="middle">
                                <Spin size="large" />
                              </Row>
                            }>
                            <Routes />
                          </Suspense>
                        </div>
                      </Content>
                    </>
                  </Layout>
                </Layout>
              </AppContextProvider>
            </AuthContextProvider>
          </ConfigProvider>
        </ErrorBoundary>
      </AlertContextProvider>
    );
  } else if (!isAuthorized) {
    history.push('/login');
    window.location.reload();
  }
  return (
    <AlertContextProvider>
      <ErrorBoundary fallback={ErrorScreen}>
        <ConfigProvider locale={localeConfig[currentLanguage as ResourceKey]}>
          <AuthContextProvider afterAuth={fetchInitialAppData}>
            <AppContextProvider defaultData={initialAppData || {}}>
              <TaskContextProvider>
                <Layout style={{ height: '100%', paddingTop: 64 }}>
                  <PinVerificationModal />
                  {initialAppData ? (
                    <>
                      <AppMenu
                        menuCollapsed={menuCollapsed}
                        closeMenu={closeMenu}
                        openMenu={openMenu}
                        menuData={menuData}
                      />
                    </>
                  ) : null}
                  <Layout>
                    {initialAppData ? (
                      <>
                        <AppHeader
                          openMenu={openMenu}
                          menuCollapsed={menuCollapsed}
                          openPinModal={() => setIsPinModalOpen(true)}
                        />
                        <Content className={'app-content'}>
                          <div className={'app-content-child'}>
                            <Suspense
                              fallback={
                                <Row
                                  className="h-full"
                                  justify="center"
                                  align="middle">
                                  <Spin size="large" />
                                </Row>
                              }>
                              <Routes />
                            </Suspense>
                          </div>
                        </Content>
                      </>
                    ) : (
                      <Row className="h-full" justify="center" align="middle">
                        <Spin size="large" />
                      </Row>
                    )}
                  </Layout>
                  {initialAppData ? (
                    <>
                      <AppWorkflow />
                    </>
                  ) : null}
                </Layout>
              </TaskContextProvider>
            </AppContextProvider>
          </AuthContextProvider>
        </ConfigProvider>
      </ErrorBoundary>
    </AlertContextProvider>
  );
}

export default App;
