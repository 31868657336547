/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserDto } from '../models/CreateUserDto';
import type { GetUsersDto } from '../models/GetUsersDto';
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { UserModel } from '../models/UserModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns UserModel
     * @throws ApiError
     */
    public userAdminControllerGetAll({
        requestBody,
    }: {
        requestBody: GetUsersDto,
    }): CancelablePromise<Array<UserModel>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/user/get-all',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserModel
     * @throws ApiError
     */
    public userAdminControllerUpdate({
        requestBody,
    }: {
        requestBody: UpdateUserDto,
    }): CancelablePromise<UserModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/user/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserModel
     * @throws ApiError
     */
    public userAdminControllerCreate({
        requestBody,
    }: {
        requestBody: CreateUserDto,
    }): CancelablePromise<UserModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/user/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
