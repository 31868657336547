import React, { useEffect, useState } from 'react';
import styles from './SetCredentials.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Input } from 'antd';
import { OtpInput } from './components/OtpInput';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button as NewButton } from 'components/Button';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import useQuery from 'hooks/useQuery';
import { UserModel, apiClient } from 'service/api';
import { PIN_CODE_LENGTH, RexError } from 'utils/constants';
import { PasswordStrengthValidator } from './components/PasswordStrengthValidator';

export type SetCredentialsFormData = {
  password: string;
  pinCode: string;
};

const passwordMinLength = 12;

const validationSchema = Yup.object({
  password: Yup.string()
    .required('validation_required')
    .min(passwordMinLength, 'password_min_length'),
  pinCode: Yup.string()
    .required('validation_required')
    .length(PIN_CODE_LENGTH, 'pin_code_min_length'),
});

export const SetCredentials = () => {
  const [pinCodeVisible, setPinCodeVisible] = useState<boolean>(false);
  const [user, setUser] = useState<UserModel>();
  const [error, setError] = useState<string | undefined>(undefined);
  const [isPasswordStrong, setIsPasswordStrong] = useState<boolean>(false);
  const { t } = useTranslation();

  const history = useHistory();
  const query = useQuery();
  const token = query.get('token');

  useEffectOnce(() => {
    localStorage.removeItem('token');
    (async () => {
      if (!token) {
        history.push('/login');
      } else {
        localStorage.setItem('token', token as string);
        (async () => {
          try {
            const userRes = await apiClient.userAuth.userAuthControllerMe();

            setUser(userRes);
          } catch (error) {
            localStorage.removeItem('token');
            history.push('/login');
          }
        })();
        localStorage.removeItem('token');
      }
    })();
  });

  const {
    values: formValues,
    handleSubmit,
    setFieldValue,
    errors,
  } = useFormik<SetCredentialsFormData>({
    initialValues: { password: '', pinCode: '' },
    validationSchema: validationSchema,
    onSubmit: async ({ password, pinCode }) => {
      if (!user) {
        return;
      }
      localStorage.setItem('token', token as string);
      try {
        await apiClient.userAdmin.userAdminControllerUpdate({
          requestBody: {
            id: user.id,
            password,
            pin: pinCode,
          },
        });
        localStorage.setItem('role', user.roles?.[0].role);
        window.location.href = '/m/calendar';
      } catch (error: any) {
        if (error?.body?.code === RexError.PIN_ALREADY_USED) {
          setError('please_use_other_pin_code');
        }
      }
      // TODO handle non-unique pin code error
    },
  });

  useEffect(() => {
    if (error) {
      setError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <h1 className={styles.title}>
          <Trans
            i18nKey={'set_your_pin_and_password'}
            components={{ HIGHLIGHT: <span className={styles.hightlight} /> }}
          />
        </h1>
        <span className={styles.subtitle}>
          {t('this_will_allow_you_to_access_rexos')}
        </span>

        <Form
          className={styles.form}
          layout="vertical"
          onSubmitCapture={handleSubmit}>
          <Form.Item
            label={
              <span>
                <Trans
                  i18nKey={'set_password_label'}
                  components={{ BOLD: <b /> }}
                  values={{ length: passwordMinLength}}
                />
              </span>
            }>
            <Input.Password
              size={'large'}
              name="password"
              placeholder={t('password')}
              value={formValues['password']}
              onChange={(e) => {
                setFieldValue('password', e.target.value);
              }}
              onPressEnter={() => {
                handleSubmit();
              }}
              minLength={passwordMinLength}
            />

            <PasswordStrengthValidator
              password={formValues['password']}
              minLength={passwordMinLength}
              setIsValid={setIsPasswordStrong}
            />
          </Form.Item>

          <Form.Item
            label={
              <span>
                <Trans
                  i18nKey={'set_pin_code_label'}
                  components={{ BOLD: <b /> }}
                />
              </span>
            }>
            <div className={styles.pinCodeContainer}>
              <OtpInput
                value={formValues.pinCode}
                setValue={(val: string) => setFieldValue('pinCode', val)}
                length={PIN_CODE_LENGTH}
                type={pinCodeVisible ? 'text' : 'password'}
                errorKey={errors['pinCode'] || error}
              />
              <button
                type="button"
                className={styles.pinCodeVisibilityToggle}
                onClick={() => setPinCodeVisible((val) => !val)}>
                {pinCodeVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              </button>
            </div>
          </Form.Item>
        </Form>
        <div className={styles.controls}>
          <NewButton type="secondary" onClick={() => history.push('/')}>
            {t('back')}
          </NewButton>
          <NewButton
            disabled={
              !isPasswordStrong ||
              !!Object.keys(errors).length ||
              !Object.values(formValues).some((e) => !!e)
            }
            onClick={() => handleSubmit()}>
            {t('next')}
          </NewButton>
        </div>
      </div>
    </div>
  );
};
