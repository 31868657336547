/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppFeedbackModel } from '../models/AppFeedbackModel';
import type { FeedbackModel } from '../models/FeedbackModel';
import type { SubmitAppFeedbackDto } from '../models/SubmitAppFeedbackDto';
import type { SubmitFeedbackDto } from '../models/SubmitFeedbackDto';
import type { TriggerUpdateDto } from '../models/TriggerUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FeedbackService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public feedbackTriggerControllerSendCoupon({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/feedback/trigger/event/handle-feedback',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public feedbackTriggerControllerSendFeedbackInternally({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/feedback/trigger/event/handle-feedback-internal',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FeedbackModel
     * @throws ApiError
     */
    public feedbackControllerSubmitFeedback({
        requestBody,
    }: {
        requestBody: SubmitFeedbackDto,
    }): CancelablePromise<FeedbackModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/feedback/submit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AppFeedbackModel
     * @throws ApiError
     */
    public feedbackControllerSubmitAppFeedback({
        requestBody,
    }: {
        requestBody: SubmitAppFeedbackDto,
    }): CancelablePromise<AppFeedbackModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/feedback/app/submit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
