/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCustomerPetDto } from '../models/CreateCustomerPetDto';
import type { CreateManyCustomerAttributionsDto } from '../models/CreateManyCustomerAttributionsDto';
import type { CreateVerificationDto } from '../models/CreateVerificationDto';
import type { CustomerAttributionModel } from '../models/CustomerAttributionModel';
import type { CustomerChangePasswordDto } from '../models/CustomerChangePasswordDto';
import type { CustomerChangePasswordRo } from '../models/CustomerChangePasswordRo';
import type { CustomerExtendedRo } from '../models/CustomerExtendedRo';
import type { CustomerModel } from '../models/CustomerModel';
import type { CustomerPetByIdDto } from '../models/CustomerPetByIdDto';
import type { CustomerResetPasswordDto } from '../models/CustomerResetPasswordDto';
import type { PetModel } from '../models/PetModel';
import type { UpdateCustomerDto } from '../models/UpdateCustomerDto';
import type { VerifyDto } from '../models/VerifyDto';
import type { VerifyRo } from '../models/VerifyRo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns CustomerExtendedRo
     * @throws ApiError
     */
    public customerControllerUpdate({
        requestBody,
    }: {
        requestBody: UpdateCustomerDto,
    }): CancelablePromise<CustomerExtendedRo> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/customer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VerifyRo
     * @throws ApiError
     */
    public customerControllerVerifyByCode({
        requestBody,
    }: {
        requestBody: VerifyDto,
    }): CancelablePromise<VerifyRo> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/customer/verification/phone-number',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public customerControllerCreateVerification({
        requestBody,
    }: {
        requestBody: CreateVerificationDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/customer/verification/phone-number',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public customerControllerResetPassword({
        requestBody,
    }: {
        requestBody: CustomerResetPasswordDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/customer/password/reset',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomerChangePasswordRo
     * @throws ApiError
     */
    public customerControllerChangePassword({
        requestBody,
    }: {
        requestBody: CustomerChangePasswordDto,
    }): CancelablePromise<CustomerChangePasswordRo> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/customer/password/change',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PetModel
     * @throws ApiError
     */
    public customerControllerCreateCustomerPet({
        requestBody,
    }: {
        requestBody: CreateCustomerPetDto,
    }): CancelablePromise<PetModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/customer/pet',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PetModel
     * @throws ApiError
     */
    public customerControllerGetCustomerPet({
        petId,
    }: {
        petId: string,
    }): CancelablePromise<PetModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/customer/pet',
            query: {
                'petId': petId,
            },
        });
    }

    /**
     * @returns number
     * @throws ApiError
     */
    public customerControllerDeleteCustomerPet({
        requestBody,
    }: {
        requestBody: CustomerPetByIdDto,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/customer/pet/delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomerAttributionModel
     * @throws ApiError
     */
    public customerControllerCreateManyCustomerAttributions({
        requestBody,
    }: {
        requestBody: CreateManyCustomerAttributionsDto,
    }): CancelablePromise<Array<CustomerAttributionModel>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/customer/attribution/create-many',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomerModel
     * @throws ApiError
     */
    public customerControllerDeleteCustomer(): CancelablePromise<CustomerModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/customer/delete',
        });
    }

}
