/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetAdminAppointmentListDto = {
    firstName?: string;
    lastName?: string;
    petName?: string;
    email?: string;
    treatmentId?: string;
    type?: GetAdminAppointmentListDto.type;
    clinicId?: string;
    customerId?: string;
    doctorId?: string;
    from?: string;
    to?: string;
    statuses?: Array<'pending' | 'waiting_payment' | 'expired' | 'approved' | 'in_waiting_room' | 'in_treatment' | 'checking_out' | 'missed' | 'canceled' | 'completed'>;
    excludeStatuses?: Array<'pending' | 'waiting_payment' | 'expired' | 'approved' | 'in_waiting_room' | 'in_treatment' | 'checking_out' | 'missed' | 'canceled' | 'completed'>;
    orderBy?: any;
    orderDirection?: GetAdminAppointmentListDto.orderDirection;
    petId?: string;
};

export namespace GetAdminAppointmentListDto {

    export enum type {
        APPOINTMENT = 'appointment',
        INTERNAL = 'internal',
        BLOCKER_EXPIRING = 'blocker_expiring',
        BREAK = 'break',
        MEETING = 'meeting',
    }

    export enum orderDirection {
        DESC = 'desc',
        ASC = 'asc',
    }


}

