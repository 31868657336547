/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserModel } from './UserModel';

export type AppointmentChangeLog = {
    createdAt: string;
    actor: AppointmentChangeLog.actor;
    property: AppointmentChangeLog.property;
    oldValue?: string;
    newValue?: string;
    user?: UserModel;
};

export namespace AppointmentChangeLog {

    export enum actor {
        CUSTOMER = 'customer',
        TEAM_MEMBER = 'team_member',
        TEAM_MEMBER_VIA_INB = 'team_member_via_inb',
        AUTOMATIC_PROCESS = 'automatic_process',
    }

    export enum property {
        CLINIC = 'clinic',
        DOCTOR = 'doctor',
        DATE = 'date',
        DURATION = 'duration',
        COMMENT = 'comment',
        STATUS = 'status',
        TREATMENT = 'treatment',
        INSTRUCTIONS = 'instructions',
        PET = 'pet',
    }


}

