/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClinicIdDto } from '../models/ClinicIdDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InbehandlungService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns boolean
     * @throws ApiError
     */
    public ibhControllerFindMismataches({
        xSecretHeader,
        requestBody,
    }: {
        /**
         * Secret
         */
        xSecretHeader: string,
        requestBody: ClinicIdDto,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/customer-and-pet/find-mismatches',
            headers: {
                'x-secret-header': xSecretHeader,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns boolean
     * @throws ApiError
     */
    public ibhControllerSyncIbhIntoRex({
        xSecretHeader,
        requestBody,
    }: {
        /**
         * Secret
         */
        xSecretHeader: string,
        requestBody: ClinicIdDto,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/customer-and-pet/sync-ibh-into-rex',
            headers: {
                'x-secret-header': xSecretHeader,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns boolean
     * @throws ApiError
     */
    public ibhControllerSyncRexIntoIbh({
        xSecretHeader,
    }: {
        /**
         * Secret
         */
        xSecretHeader: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/customer-and-pet/sync-rex-into-ibh',
            headers: {
                'x-secret-header': xSecretHeader,
            },
        });
    }

    /**
     * @returns boolean
     * @throws ApiError
     */
    public ibhControllerSyncFutureAppointments({
        xSecretHeader,
        requestBody,
    }: {
        /**
         * Secret
         */
        xSecretHeader: string,
        requestBody: ClinicIdDto,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/appointment/sync',
            headers: {
                'x-secret-header': xSecretHeader,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns boolean
     * @throws ApiError
     */
    public ibhControllerSyncMedicaments({
        xSecretHeader,
    }: {
        /**
         * Secret
         */
        xSecretHeader: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/medicament/sync',
            headers: {
                'x-secret-header': xSecretHeader,
            },
        });
    }

    /**
     * @returns boolean
     * @throws ApiError
     */
    public ibhControllerSyncVisits({
        xSecretHeader,
    }: {
        /**
         * Secret
         */
        xSecretHeader: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/visit/sync',
            headers: {
                'x-secret-header': xSecretHeader,
            },
        });
    }

    /**
     * @returns boolean
     * @throws ApiError
     */
    public ibhControllerSyncGotAreas({
        xSecretHeader,
    }: {
        /**
         * Secret
         */
        xSecretHeader: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/got-area/sync',
            headers: {
                'x-secret-header': xSecretHeader,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public ibhControllerSyncIbhVisits({
        xSecretHeader,
        requestBody,
    }: {
        /**
         * Secret
         */
        xSecretHeader: string,
        requestBody: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/visit/sync-ibh',
            headers: {
                'x-secret-header': xSecretHeader,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
