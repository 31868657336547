/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserModel } from '../models/UserModel';
import type { UserPinSignInDto } from '../models/UserPinSignInDto';
import type { UserPinSignInRo } from '../models/UserPinSignInRo';
import type { UserResetCredentialsDto } from '../models/UserResetCredentialsDto';
import type { UserResetCredentialsRo } from '../models/UserResetCredentialsRo';
import type { UserSignInDto } from '../models/UserSignInDto';
import type { UserSignInRo } from '../models/UserSignInRo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserAuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns UserModel
     * @throws ApiError
     */
    public userAuthControllerMe(): CancelablePromise<UserModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/auth/user/me',
        });
    }

    /**
     * @returns UserSignInRo
     * @throws ApiError
     */
    public userAuthControllerSignIn({
        requestBody,
    }: {
        requestBody: UserSignInDto,
    }): CancelablePromise<UserSignInRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/auth/user/sign-in',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserPinSignInRo
     * @throws ApiError
     */
    public userAuthControllerSignInByPin({
        requestBody,
    }: {
        requestBody: UserPinSignInDto,
    }): CancelablePromise<UserPinSignInRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/auth/user/pin/sign-in',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserResetCredentialsRo
     * @throws ApiError
     */
    public userAuthControllerResetCredentials({
        requestBody,
    }: {
        requestBody: UserResetCredentialsDto,
    }): CancelablePromise<UserResetCredentialsRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/auth/user/reset-credentials',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
