/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TaskUpdateDto = {
    id: string;
    title?: string;
    description?: string;
    date?: string;
    status?: TaskUpdateDto.status;
    clinicId?: string;
    customerId?: string;
    assigneeId?: string;
    petId?: string;
};

export namespace TaskUpdateDto {

    export enum status {
        PENDING = 'pending',
        CANCELED = 'canceled',
        COMPLETED = 'completed',
    }


}

