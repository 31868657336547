/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ClinicModel } from './ClinicModel';
import type { CustomerModel } from './CustomerModel';
import type { FileModel } from './FileModel';
import type { InvoiceItemModel } from './InvoiceItemModel';

export type InvoiceModel = {
    createdAt: string;
    updatedAt: string;
    number: string | null;
    date: string;
    price: number;
    status: InvoiceModel.status;
    paymentStatus: InvoiceModel.paymentStatus;
    clinicId: string;
    customerId: string;
    fileId: string | null;
    clinic: ClinicModel;
    customer: CustomerModel;
    items: Array<InvoiceItemModel>;
    file: FileModel | null;
    id: string;
};

export namespace InvoiceModel {

    export enum status {
        DRAFT = 'draft',
        OPEN = 'open',
        COMPLETE = 'complete',
    }

    export enum paymentStatus {
        DRAFT = 'draft',
        OPEN = 'open',
        PAID = 'paid',
    }


}

