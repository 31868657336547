/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClinicModel } from '../models/ClinicModel';
import type { GetClinicBySlug } from '../models/GetClinicBySlug';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClinicService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ClinicModel
     * @throws ApiError
     */
    public clinicControllerGetAll(): CancelablePromise<Array<ClinicModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/clinic',
        });
    }

    /**
     * @returns ClinicModel
     * @throws ApiError
     */
    public clinicControllerGetTelemedicine(): CancelablePromise<ClinicModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/clinic/telemedicine',
        });
    }

    /**
     * @returns ClinicModel
     * @throws ApiError
     */
    public clinicControllerGetOne({
        id,
    }: {
        id: string,
    }): CancelablePromise<ClinicModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/clinic/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ClinicModel
     * @throws ApiError
     */
    public clinicControllerGetBySlug({
        requestBody,
    }: {
        requestBody: GetClinicBySlug,
    }): CancelablePromise<ClinicModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/clinic/get-by-slug',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
