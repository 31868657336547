/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AppointmentService } from './services/AppointmentService';
import { AppointmentAdminService } from './services/AppointmentAdminService';
import { AppointmentCommentAdminService } from './services/AppointmentCommentAdminService';
import { AppointmentTelemedicineService } from './services/AppointmentTelemedicineService';
import { AppointmentUnauthenticatedService } from './services/AppointmentUnauthenticatedService';
import { AppVersionService } from './services/AppVersionService';
import { AvailabilityAdminService } from './services/AvailabilityAdminService';
import { AvailabilityTriggerService } from './services/AvailabilityTriggerService';
import { AvailabilityUnauthenticatedService } from './services/AvailabilityUnauthenticatedService';
import { ClinicService } from './services/ClinicService';
import { ClinicAdminService } from './services/ClinicAdminService';
import { ClinicTriggerService } from './services/ClinicTriggerService';
import { CouponService } from './services/CouponService';
import { CrmService } from './services/CrmService';
import { CustomerService } from './services/CustomerService';
import { CustomerAdminService } from './services/CustomerAdminService';
import { CustomerAuthService } from './services/CustomerAuthService';
import { CustomerTriggerService } from './services/CustomerTriggerService';
import { CustomerUnauthenticatedService } from './services/CustomerUnauthenticatedService';
import { DefaultService } from './services/DefaultService';
import { DoctorAdminService } from './services/DoctorAdminService';
import { FeedbackService } from './services/FeedbackService';
import { FileService } from './services/FileService';
import { IbhAdminService } from './services/IbhAdminService';
import { IbhTriggerService } from './services/IbhTriggerService';
import { InbehandlungService } from './services/InbehandlungService';
import { InstructionAdminService } from './services/InstructionAdminService';
import { LegalNoticeService } from './services/LegalNoticeService';
import { MedicalEventService } from './services/MedicalEventService';
import { MedicalEventTriggerService } from './services/MedicalEventTriggerService';
import { NotesAdminService } from './services/NotesAdminService';
import { PaymentTransactionService } from './services/PaymentTransactionService';
import { PetService } from './services/PetService';
import { PetAdminService } from './services/PetAdminService';
import { PetCategoryService } from './services/PetCategoryService';
import { PetCategoryUnauthenticatedService } from './services/PetCategoryUnauthenticatedService';
import { ShortenedUrlService } from './services/ShortenedUrlService';
import { StaticService } from './services/StaticService';
import { StaticTriggerService } from './services/StaticTriggerService';
import { TaskAdminService } from './services/TaskAdminService';
import { TreatmentAdminService } from './services/TreatmentAdminService';
import { UserAdminService } from './services/UserAdminService';
import { UserAuthService } from './services/UserAuthService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly appointment: AppointmentService;
    public readonly appointmentAdmin: AppointmentAdminService;
    public readonly appointmentCommentAdmin: AppointmentCommentAdminService;
    public readonly appointmentTelemedicine: AppointmentTelemedicineService;
    public readonly appointmentUnauthenticated: AppointmentUnauthenticatedService;
    public readonly appVersion: AppVersionService;
    public readonly availabilityAdmin: AvailabilityAdminService;
    public readonly availabilityTrigger: AvailabilityTriggerService;
    public readonly availabilityUnauthenticated: AvailabilityUnauthenticatedService;
    public readonly clinic: ClinicService;
    public readonly clinicAdmin: ClinicAdminService;
    public readonly clinicTrigger: ClinicTriggerService;
    public readonly coupon: CouponService;
    public readonly crm: CrmService;
    public readonly customer: CustomerService;
    public readonly customerAdmin: CustomerAdminService;
    public readonly customerAuth: CustomerAuthService;
    public readonly customerTrigger: CustomerTriggerService;
    public readonly customerUnauthenticated: CustomerUnauthenticatedService;
    public readonly default: DefaultService;
    public readonly doctorAdmin: DoctorAdminService;
    public readonly feedback: FeedbackService;
    public readonly file: FileService;
    public readonly ibhAdmin: IbhAdminService;
    public readonly ibhTrigger: IbhTriggerService;
    public readonly inbehandlung: InbehandlungService;
    public readonly instructionAdmin: InstructionAdminService;
    public readonly legalNotice: LegalNoticeService;
    public readonly medicalEvent: MedicalEventService;
    public readonly medicalEventTrigger: MedicalEventTriggerService;
    public readonly notesAdmin: NotesAdminService;
    public readonly paymentTransaction: PaymentTransactionService;
    public readonly pet: PetService;
    public readonly petAdmin: PetAdminService;
    public readonly petCategory: PetCategoryService;
    public readonly petCategoryUnauthenticated: PetCategoryUnauthenticatedService;
    public readonly shortenedUrl: ShortenedUrlService;
    public readonly static: StaticService;
    public readonly staticTrigger: StaticTriggerService;
    public readonly taskAdmin: TaskAdminService;
    public readonly treatmentAdmin: TreatmentAdminService;
    public readonly userAdmin: UserAdminService;
    public readonly userAuth: UserAuthService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.appointment = new AppointmentService(this.request);
        this.appointmentAdmin = new AppointmentAdminService(this.request);
        this.appointmentCommentAdmin = new AppointmentCommentAdminService(this.request);
        this.appointmentTelemedicine = new AppointmentTelemedicineService(this.request);
        this.appointmentUnauthenticated = new AppointmentUnauthenticatedService(this.request);
        this.appVersion = new AppVersionService(this.request);
        this.availabilityAdmin = new AvailabilityAdminService(this.request);
        this.availabilityTrigger = new AvailabilityTriggerService(this.request);
        this.availabilityUnauthenticated = new AvailabilityUnauthenticatedService(this.request);
        this.clinic = new ClinicService(this.request);
        this.clinicAdmin = new ClinicAdminService(this.request);
        this.clinicTrigger = new ClinicTriggerService(this.request);
        this.coupon = new CouponService(this.request);
        this.crm = new CrmService(this.request);
        this.customer = new CustomerService(this.request);
        this.customerAdmin = new CustomerAdminService(this.request);
        this.customerAuth = new CustomerAuthService(this.request);
        this.customerTrigger = new CustomerTriggerService(this.request);
        this.customerUnauthenticated = new CustomerUnauthenticatedService(this.request);
        this.default = new DefaultService(this.request);
        this.doctorAdmin = new DoctorAdminService(this.request);
        this.feedback = new FeedbackService(this.request);
        this.file = new FileService(this.request);
        this.ibhAdmin = new IbhAdminService(this.request);
        this.ibhTrigger = new IbhTriggerService(this.request);
        this.inbehandlung = new InbehandlungService(this.request);
        this.instructionAdmin = new InstructionAdminService(this.request);
        this.legalNotice = new LegalNoticeService(this.request);
        this.medicalEvent = new MedicalEventService(this.request);
        this.medicalEventTrigger = new MedicalEventTriggerService(this.request);
        this.notesAdmin = new NotesAdminService(this.request);
        this.paymentTransaction = new PaymentTransactionService(this.request);
        this.pet = new PetService(this.request);
        this.petAdmin = new PetAdminService(this.request);
        this.petCategory = new PetCategoryService(this.request);
        this.petCategoryUnauthenticated = new PetCategoryUnauthenticatedService(this.request);
        this.shortenedUrl = new ShortenedUrlService(this.request);
        this.static = new StaticService(this.request);
        this.staticTrigger = new StaticTriggerService(this.request);
        this.taskAdmin = new TaskAdminService(this.request);
        this.treatmentAdmin = new TreatmentAdminService(this.request);
        this.userAdmin = new UserAdminService(this.request);
        this.userAuth = new UserAuthService(this.request);
    }
}

