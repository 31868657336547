/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerSignUpPetDto } from './CustomerSignUpPetDto';
import type { LegalNoticesDto } from './LegalNoticesDto';

export type CustomerSignUpDto = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    country: string;
    language: CustomerSignUpDto.language;
    pets: Array<CustomerSignUpPetDto>;
    appointmentIds?: Array<string>;
    legalNotices: LegalNoticesDto;
    consentedMarketing?: boolean;
    couponCode?: string;
};

export namespace CustomerSignUpDto {

    export enum language {
        EN = 'en',
        DE = 'de',
    }


}

