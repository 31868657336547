/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppointmentIdDto } from '../models/AppointmentIdDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IbhAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns boolean
     * @throws ApiError
     */
    public ibhAdminControllerSyncAppointment({
        requestBody,
    }: {
        requestBody: AppointmentIdDto,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/ibh/sync-appointment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
