export const TELEMEDICINE_CLINIC_ID = '8f9e287e-fe69-42eb-a35e-1ea888600e60';

export const GENERAL_TREATMENT_ID = '7b8082f5-9c5b-402d-9ee3-10979b6af300';

export const PHONE_NUMBER_REGEX =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const LANDLINE_NUMBER_REGEX = /^\+49\d{6,11}$/;

export const REX_PHONE_PREFIX = '+49';

export const SURGERY_TREATMENT_ID = 'bd297993-458a-417c-b76c-762a70240f9c';
export const DIRECT_SALES_TREATMENT_ID = '2e4a0ba7-22c3-47d3-a329-22cb4a56f539';

export const PIN_CODE_LENGTH = 4;

export const VERIFICATION_PIN_TIMEOUT_IN_SECONDS = 300;

export enum RexError {
  APPOINTMENT_NOT_FOUND = 1000,
  EMAIL_ALREADY_VERIFIED = 1001,
  INVALID_VERIFICATION = 1002,
  CUSTOMER_NOT_FOUND = 1003,
  HASH_EXPIRED = 1004,
  CLINIC_NOT_FOUND = 1005,
  DOCTOR_NOT_FOUND = 1006,
  INVALID_APPOINTMENT_TYPE = 1007,
  OUTSIDE_ALLOWED_DATE_RANGE = 1008,
  DATABASE_ERROR = 1009,
  FAILED_SENDING_EMAIL = 1010,
  TIME_NOT_AVAILABLE = 1011,
  FAILED_SENDING_SMS = 1012,
  APPOINTMENT_EXPIRED = 1011,
  UNAVAILABLE_VOUCHERS = 1012,
  DOCTOR_CANNOT_TREAT = 1013,
  INACCCESSABLE_FOR_COMPLETED_APPOINTMENT = 1014,
  PAYMENT_NOT_FOUND = 1015,
  EMAIL_ALREADY_REGISTERED = 1016,
  INCORRECT_CREDENTIALS = 1017,
  BAD_INPUT = 1018,
  STATUS_TRANSITION_NOT_AVAILABLE = 1019,
  UNAUTHORIZED = 1020,
  NOTE_NOT_FOUND = 1021,
  PHONE_NUMBER_ALREADY_REGISTERED = 1022,
  USER_NOT_FOUND = 1023,

  TREATMENT_NOT_FOUND = 1025,
  DATA_VALIDATION_FAILED = 1026,
  PASSWORD_NOT_SET = 1027,
  RESOURCE_NOT_FOUND = 1028,
  FILE_UPLOAD_FAILED = 1029,
  UNPROCESSABLE_ENTITY = 1030,
  PIN_ALREADY_USED = 1031,
}
