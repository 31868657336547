import React, { ReactNode } from 'react';
import { Button, Form, Input, Tooltip } from 'antd';
import { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { useTranslation, TFuncKey } from 'react-i18next';

interface TextAreaProps extends Omit<AntdTextAreaProps, 'onChange'> {
  label?: string;
  errorMessage?: string;
  tooltip?: string;
  rightItem?: ReactNode;
  onChange: (value: string) => void;
  onSearch?: () => void;
}

export default function TextArea(props: TextAreaProps) {
  const {
    errorMessage,
    label,
    value,
    onChange,
    rightItem,
    onSearch,
    name,
    required,
    ...rest
  } = props;

  const { t } = useTranslation();

  if (props.hidden) return null;

  const inputCmp = (
    <>
      <Input.TextArea
        placeholder={props.placeholder}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            if (onSearch) {
              onSearch();
            }
          }
        }}
        value={value}
        allowClear
        onChange={(e) => {
          onChange(e.target.value);
        }}
        {...rest}
      />
      {props.tooltip && (
        <Tooltip trigger={['click']} title={props.tooltip} placement="right">
          <Button type="link" icon={<QuestionCircleTwoTone />} />
        </Tooltip>
      )}
      {rightItem}
    </>
  );
  const formattedLabel = label ? (
    <div
      className={`${
        required && 'antd-field-required'
      } w-32 md:w-40 mr-2 flex justify-end items-center`}>
      {label}
    </div>
  ) : undefined;
  return (
    <Form.Item
      help={errorMessage && t(errorMessage as TFuncKey)}
      validateStatus={typeof errorMessage === 'string' ? 'error' : 'success'}
      label={formattedLabel}>
      <div
        style={{ flex: 1, overflow: 'hidden', display: 'flex' }}
        className={'rex-field rex-textarea'}>
        <div className={'flex-1 flex'}>{inputCmp}</div>
      </div>
    </Form.Item>
  );
}
