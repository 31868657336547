import { Modal } from 'antd';
import React, { ReactNode } from 'react';

import styles from './TaskModal.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type UserModalProps = {
  title: string;
  children: ReactNode;
  onClose: () => void;
};

export const TaskModal = ({ children, title, onClose }: UserModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      title={
        <>
          <span className={styles.title}>{title}</span> -{' '}
          <span className="font-bold">{t('task')}</span>
        </>
      }
      centered
      width="100%"
      className={classNames(
        styles.modal,
        // userData?.id ? styles.modalForExistingAppointment : null,
      )}
      footer={false}>
      {children}
    </Modal>
  );
};
