import moment from 'moment-timezone';
import { i18n } from 'i18next';

import { ResourceKey } from 'i18n';

function getBase64(file: File): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  window.location.href = '/login';
}

function getTime(date: moment.MomentInput) {
  return moment(date).diff(moment(date).startOf('day')) / 1000 / 60 / 60;
}

const timeStringToFloat = (time: string) => {
  if (!time) return undefined;
  const [hours, minutes] = time.split(':').map((t) => Number.parseInt(t));

  return hours + minutes / 60;
};

const translate = (value: Record<ResourceKey, string>, i18n: i18n) => {
  if (!value) return '';
  return value?.[i18n?.resolvedLanguage as ResourceKey] || value?.en || '';
};

const trimObject = (obj: Object) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      value?.trim ? value.trim() : value,
    ]),
  );
};

export { getBase64, logout, getTime, timeStringToFloat, translate, trimObject };
