/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetDoctorsDto = {
    clinicId?: string;
    treatmentId?: string;
    role?: GetDoctorsDto.role;
};

export namespace GetDoctorsDto {

    export enum role {
        GOD = 'god',
        MANAGER = 'manager',
        CUSTOMER = 'customer',
        ANONYMOUS = 'anonymous',
        DOC = 'doc',
        ASSISTANT = 'assistant',
    }


}

