import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

type ButtonHTMLType = 'submit' | 'button';
type ButtonType = 'primary' | 'secondary' | 'tertiary';

type ButtonProps = {
  form?: string;
  className?: string;
  type?: ButtonType;
  disabled?: boolean;
  block?: boolean;
  children?: ReactNode;
  htmlType?: ButtonHTMLType;
  target?: '_blank' | '_self' | '_parent' | '_top';
  href?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const Button = ({
  className,
  disabled,
  children,
  href,
  block,
  target,
  type = 'primary',
  htmlType = 'button',
  onClick,
  form,
}: ButtonProps) => {
  return href ? (
    <a
      href={href}
      target={target}
      className={classNames(className, styles.button, {
        [styles[type]]: type,
        [styles.block]: block,
      })}>
      {children}
    </a>
  ) : (
    <button
      form={form}
      className={classNames(className, styles.button, {
        [styles[type]]: type,
        [styles.block]: block,
      })}
      type={htmlType}
      disabled={disabled}
      onClick={onClick}>
      {children}
    </button>
  );
};
