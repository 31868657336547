import React, { useState, useEffect, useMemo } from 'react';
import { Layout, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button as NewButton } from 'components/Button';

import styles from './AppWorkflow.module.scss';
import { ReactComponent as ArrowIcon } from './assets/arrowIcon.svg';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { TaskList } from './components/TaskList';
import { TaskFormModal } from './components/TaskFormModal';
import useTasks from 'store/TaskContext';
import { TaskModel } from 'service/api';
import useAuth from 'store/AuthContext';
import { PlusCircleFilled } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useModal } from 'hooks/useModal';
import classNames from 'classnames';
import useQuery from 'hooks/useQuery';

const { Sider } = Layout;

const AppWorkflow = () => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);
  const closeMenu = () => {
    setMenuCollapsed(true);
  };
  const { openModal, modal } = useModal();
  const [isFormDirty, setIsFormDirty] = useState<boolean>(true);

  const [isHidden, setIsHidden] = useState<boolean>(
    history.location.pathname !== '/m/calendar',
  );

  useEffect(() => {
    const unregisterListener = history.listen((e) => {
      setIsHidden(e.pathname !== '/m/calendar');
    });
    return () => {
      unregisterListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openMenu = () => {
    setMenuCollapsed(false);
  };
  const { t } = useTranslation();
  const { tasks, sortAndSetTasks } = useTasks();
  const { authData } = useAuth();
  const [showDone, setShowDone] = useState<boolean>(false);
  const [editingTask, setEditingTask] = useState<null | { id: string }>(
    query.get('taskId') ? { id: query.get('taskId') as string } : null,
  );
  const [showMyTasksOnly, setShowMyTasksOnly] = useState<boolean>(true);

  const todoTasks = useMemo(() => {
    return tasks.filter((task) => task.status === TaskModel.status.PENDING);
  }, [tasks]);

  const doneTasks = useMemo(() => {
    return tasks.filter((task) => task.status === TaskModel.status.COMPLETED);
  }, [tasks]);

  const afterTodoFilter = useMemo(() => {
    return showDone ? doneTasks : todoTasks;
  }, [doneTasks, showDone, todoTasks]);

  const myTasks = useMemo(() => {
    return afterTodoFilter.filter(
      (task) => task.assigneeId === authData?.id || task.assigneeId === null,
    );
  }, [afterTodoFilter, authData?.id]);

  const allTasks = useMemo(() => {
    return afterTodoFilter;
  }, [afterTodoFilter]);

  const afterOwnerFilter = useMemo(() => {
    return showMyTasksOnly ? myTasks : allTasks;
  }, [allTasks, myTasks, showMyTasksOnly]);

  const currentlyDisplayedTasks = useMemo(() => {
    return afterOwnerFilter;
  }, [afterOwnerFilter]);

  useEffect(() => {
    if (showDone) {
      setShowDone(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuCollapsed]);

  const removeTaskIdFromQuery = () => {
    query.delete('taskId');
    history.push({
      pathname: pathname,
      search: '?' + query.toString(),
    });
  };

  if (isHidden) {
    return null;
  }

  return (
    <Sider
      collapsedWidth={130}
      trigger={null}
      collapsed={menuCollapsed}
      width={400}
      className={styles.sider}>
      <div className={styles.menuControl}>
        {modal}
        {menuCollapsed ? (
          <MenuFoldOutlined
            style={{
              fontSize: 18,
              padding: '0 24px',
            }}
            onClick={openMenu}
          />
        ) : (
          <MenuUnfoldOutlined
            style={{
              fontSize: 18,
              padding: '0 24px',
            }}
            onClick={closeMenu}
          />
        )}
      </div>
      <Menu
        theme="dark"
        mode="inline"
        style={{ borderRight: 0 }}
        expandIcon={
          <div>
            <ArrowIcon style={{ minWidth: 13, minHeight: 7 }} />
          </div>
        }
        className={styles.appMenu}>
        {!menuCollapsed ? (
          <>
            <div>
              <div className={styles.switcher}>
                <NewButton
                  type={!showDone ? 'primary' : 'tertiary'}
                  onClick={() => {
                    setShowDone(false);
                  }}>
                  {t('todo')} ({todoTasks.length})
                </NewButton>
                <NewButton
                  type={showDone ? 'primary' : 'tertiary'}
                  onClick={() => {
                    setShowDone(true);
                  }}>
                  {t('done')} ({doneTasks.length})
                </NewButton>
              </div>
              <div className={styles.switcher}>
                <NewButton
                  type={showMyTasksOnly ? 'primary' : 'tertiary'}
                  onClick={() => {
                    setShowMyTasksOnly(true);
                  }}>
                  {t('my_tasks')} ({myTasks.length})
                </NewButton>
                <NewButton
                  type={!showMyTasksOnly ? 'primary' : 'tertiary'}
                  onClick={() => {
                    setShowMyTasksOnly(false);
                  }}>
                  {t('all_tasks')} ({allTasks.length})
                </NewButton>
              </div>
            </div>
          </>
        ) : (
          <div className={classNames(styles.switcher, styles.switcherVertical)}>
            <NewButton
              type={showMyTasksOnly ? 'primary' : 'tertiary'}
              onClick={() => {
                setShowMyTasksOnly(true);
              }}>
              {t('my_tasks')} ({myTasks.length})
            </NewButton>
            <NewButton
              type={!showMyTasksOnly ? 'primary' : 'tertiary'}
              onClick={() => {
                setShowMyTasksOnly(false);
              }}>
              {t('all_tasks')} ({allTasks.length})
            </NewButton>
          </div>
        )}
        <div>
          <NewButton
            type={'tertiary'}
            onClick={() => {
              setEditingTask({ id: '' });
            }}>
            {t(menuCollapsed ? 'new' : 'add_new')}
            <PlusCircleFilled className={styles.plusIcon} />
          </NewButton>
        </div>
        s
        <TaskList
          tasks={currentlyDisplayedTasks}
          onTaskOpen={(task) => {
            setEditingTask({ id: task.id });
            query.set('taskId', task.id);

            history.push({
              pathname: pathname,
              search: '?' + query.toString(),
            });
          }}
          isCollapsed={menuCollapsed}
        />
        {editingTask ? (
          <TaskFormModal
            onClose={() => {
              if (isFormDirty) {
                openModal({
                  title: t('close_confirm_title'),
                  onConfirm: () => {
                    setEditingTask(null);
                    removeTaskIdFromQuery();
                  },
                });
              } else {
                setEditingTask(null);
                removeTaskIdFromQuery();
              }
            }}
            onSave={(updatedTask) => {
              setEditingTask(null);
              removeTaskIdFromQuery();
              sortAndSetTasks(
                editingTask?.id
                  ? tasks.map((e) => {
                      if (e.id === updatedTask.id) {
                        return updatedTask;
                      }
                      return e;
                    })
                  : [...tasks, updatedTask],
              );
            }}
            taskId={editingTask?.id}
            onDirtyChange={setIsFormDirty}
          />
        ) : null}
      </Menu>
    </Sider>
  );
};

export default AppWorkflow;
