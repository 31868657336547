/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SyncIbhAppointmentStatusRo } from '../models/SyncIbhAppointmentStatusRo';
import type { SyncIbhVisitInvestigationsDto } from '../models/SyncIbhVisitInvestigationsDto';
import type { TriggerUpdateDto } from '../models/TriggerUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IbhTriggerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public ibhTriggerControllerUpdateCustomer({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/trigger/event/update-customer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public ibhTriggerControllerUpdateAppointment({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/trigger/event/update-appointment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public ibhTriggerControllerUpdatePet({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/trigger/event/update-pet',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public ibhTriggerControllerUpdateAppointmentStatus({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/trigger/event/update-appointment-status',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SyncIbhAppointmentStatusRo
     * @throws ApiError
     */
    public ibhTriggerControllerGetAppointmentStatus(): CancelablePromise<SyncIbhAppointmentStatusRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/trigger/cron/sync-appointments-statuses',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public ibhTriggerControllerSyncIbhVisits(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/trigger/cron/sync-ibh-visits',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public ibhTriggerControllerSyncVisitInvestigations({
        requestBody,
    }: {
        requestBody: SyncIbhVisitInvestigationsDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/trigger/cron/sync-visit-investigations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public ibhTriggerControllerUpdateVisitInvestigation({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/trigger/event/update-visit-investigation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public ibhTriggerControllerUpdateVisit({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/ibh/trigger/event/update-visit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
