/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PromoteAppDto } from '../models/PromoteAppDto';
import type { ReactivateCustomersDto } from '../models/ReactivateCustomersDto';
import type { ReactivateCustomersRo } from '../models/ReactivateCustomersRo';
import type { TriggerUpdateDto } from '../models/TriggerUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomerTriggerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public customerTriggerControllerHandleConsentUpdate({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/customer/trigger/event/marketing',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReactivateCustomersRo
     * @throws ApiError
     */
    public customerTriggerControllerReactivateXMonthsBefore({
        requestBody,
    }: {
        requestBody: ReactivateCustomersDto,
    }): CancelablePromise<ReactivateCustomersRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/customer/trigger/cron/reactivate-customers-x-months-after',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReactivateCustomersRo
     * @throws ApiError
     */
    public customerTriggerControllerPromoteAppXDaysAfter({
        requestBody,
    }: {
        requestBody: PromoteAppDto,
    }): CancelablePromise<ReactivateCustomersRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/customer/trigger/cron/promote-app-x-days-after',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public paymentTransactionTriggerControllerRefundCron(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/payment/trigger/cron/refund',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public paymentTransactionTriggerControllerHandlePaymentTransactionUpdate({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/payment/trigger/event/handle-payment-transaction-update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
