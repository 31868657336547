import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';

import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

moment.tz.setDefault('Europe/Berlin');
moment.locale('de', {
  week: {
    dow: 1,
    doy: 1,
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
