/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppointmentModel } from './AppointmentModel';
import type { CustomerModel } from './CustomerModel';
import type { FileModel } from './FileModel';
import type { PetCategoryModel } from './PetCategoryModel';

export type PetModel = {
    createdAt: string;
    updatedAt: string;
    name: string;
    pictureId: string;
    breed: string;
    gender: PetModel.gender;
    birthDate: string;
    categoryId: string;
    hasInsurance: boolean;
    isNeutered: boolean;
    isOutdoor: boolean;
    alergyAndDisease: string;
    medication: string;
    chipNumber: string;
    euPassNumber: string;
    deceasedAt: string | null;
    deletedAt: string | null;
    extra: any;
    insuranceProvider?: string | null;
    insuranceIdNumber?: string | null;
    notes?: string;
    furColor: string | null;
    isGermanOrigin: boolean | null;
    originCountry: string | null;
    isDangerous: boolean;
    category: PetCategoryModel;
    picture: FileModel;
    customers: Array<CustomerModel>;
    appointments: Array<AppointmentModel>;
    isComplete: boolean;
    hasDeceased: boolean;
    isDeleted: boolean;
    id: string;
};

export namespace PetModel {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}

